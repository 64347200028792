import { AuthApiError, OktaAuth } from '@okta/okta-auth-js';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { env, logToDev } from 'utils';

import type { AccessToken, UserClaims } from '@okta/okta-auth-js';
import type { ProviderProps } from 'react';

/** The common interface shared by all versions of the auth context. */
interface BaseAuthContext {
	loginCallback: () => Promise<void>;
	login: (username: string, password: string, redirect: string | undefined) => Promise<void>;
	logout: () => Promise<void>;
	resetPassword: (email: string) => Promise<void>;
	register: (
		firstName: string,
		lastName: string,
		email: string,
		password: string,
		confirmPassword: string
	) => Promise<void>;
	clearError: () => void;
}

/** The auth context when we haven't yet checked for existing user information. */
export interface InitializedAuthContext extends BaseAuthContext {
	status: 'initialized';
	data: undefined;
}

/** The auth context when the current user is logged out. */
export interface UnauthenticatedAuthContext extends BaseAuthContext {
	status: 'unauthenticated';
	data: undefined;
}

/** The auth context when Okta is logging the user in. */
export interface AuthenticatingAuthContext extends BaseAuthContext {
	status: 'authenticating';
	data: undefined;
}

/** The auth context when the user is logged in. */
export interface AuthenticatedAuthContext extends BaseAuthContext {
	status: 'authenticated';
	data: {
		user: UserClaims;
	};
}

/** The auth context when the Okta API has returned an error. */
export interface ApiErrorAuthContext extends BaseAuthContext {
	status: 'api_error';
	data: {
		error: AuthApiError;
	};
}

/** The auth context when an error unrelated to the Okta API has occurred. */
export interface UnknownErrorAuthContext extends BaseAuthContext {
	status: 'unknown_error';
	data: {
		error: unknown;
	};
}

export type AnyAuthContext =
	| InitializedAuthContext
	| UnauthenticatedAuthContext
	| AuthenticatingAuthContext
	| AuthenticatedAuthContext
	| ApiErrorAuthContext
	| UnknownErrorAuthContext;

type AuthProviderProps = Omit<ProviderProps<AnyAuthContext>, 'value'>;

// Instantiate the Okta client for making authentication requests.
// https://github.com/okta/okta-auth-js#configuration-reference
const oktaAuth = new OktaAuth({
	clientId: env('OKTA_CLIENT_ID'),
	issuer: env('OKTA_ISSUER'),
	redirectUri: env('OKTA_LOGIN_REDIRECT_URI'),
	postLogoutRedirectUri: env('BASE_URL'),
	scopes: ['openid', 'profile', 'email'],
	pkce: true,
	cookies: {
		secure: env() === 'production',
	},
});

// Initialize the authentication context with an empty value. We can safely cast
// it to the appropriate type since this should never be accessible externally.
const authContext = createContext<AnyAuthContext>({} as AnyAuthContext);

export function AuthProvider({ children }: AuthProviderProps) {
	const [status, setStatus] = useState<AnyAuthContext['status']>('initialized');
	const [user, setUser] = useState<UserClaims | undefined>();
	const [error, setError] = useState<AuthApiError | unknown | undefined>();

	/**
	 * Attempts to log the user in using the Okta client.
	 *
	 * @param username The username to use for authentication.
	 * @param password The password to use for authentication.
	 * @param redirct optional, if a user should be redirected after login
	 */
	const login = async (username: string, password: string, redirect: string | undefined) => {
		try {
			logToDev(`Handling login request...`);

			const { status, sessionToken } = await oktaAuth.signInWithCredentials({ username, password });

			if (status !== 'SUCCESS') {
				throw new Error(`Sign-in process failed with unhandled "${status}" status!`);
			}

			if (!sessionToken) {
				throw new Error(`Sign-in process did not provide a session token!`);
			}

			setStatus('authenticating');

			// Store the origin URI of the login request so the login callback can
			// redirect back to it after successful authentication.
			oktaAuth.setOriginalUri(redirect ? redirect : window.location.href);

			await oktaAuth.signInWithRedirect({ sessionToken });
		} catch (e) {
			if (e instanceof AuthApiError) {
				logToDev(`Auth API Error:`, JSON.stringify(e, null, 2));
				setStatus('api_error');
				setError(e);
			} else {
				logToDev(`Unknown auth error:`, JSON.stringify(e, null, 2));
				setStatus('unknown_error');
				setError(e);
			}
		}
	};

	/**
	 * Submits registration info as provided by user.
	 *
	 */
	const register = async (
		firstName: string,
		lastName: string,
		email: string,
		password: string,
		confirmPassword: string
	) => {
		// eslint-disable-next-line no-console
		console.log('User submitted the register form and the API call was made!');
		// TODO: implement request to merlin-server-v2
	};

	/**
	 * Form submission that sends the password-reset email.
	 *
	 * @param email The username/email that is being sent the password-reset email.
	 */
	const resetPassword = async (email: string) => {
		try {
			logToDev(`User submitted reset password form...`);

			await oktaAuth.forgotPassword({
				username: email,
				factorType: 'EMAIL',
			});

			return;
		} catch (e) {
			if (e instanceof AuthApiError) {
				logToDev(`Auth API Error:`, JSON.stringify(e, null, 2));
				setStatus('api_error');
				setError(e);
			} else {
				logToDev(`Unknown auth error:`, JSON.stringify(e, null, 2));
				setStatus('unknown_error');
				setError(e);
			}
		}
	};

	/**
	 * Attempts to parse and store the user's ID and Access tokens from the
	 * current URL before redirecting them to the original URL of their login
	 * request. This should be called on the login callback route, which Okta
	 * forwards all authentication requests to with the necessary URL parameters
	 * appended.
	 */
	const loginCallback: AnyAuthContext['loginCallback'] = async () => {
		logToDev(`Parsing and storing user auth tokens...`);

		setStatus('authenticating');

		const { tokens } = await oktaAuth.token.parseFromUrl(window.location.href);

		oktaAuth.tokenManager.setTokens(tokens);

		await oktaAuth.handleLoginRedirect(tokens);
	};

	/**
	 * Attempts to log the user out using the Okta client.
	 */
	const logout: AnyAuthContext['logout'] = async () => {
		logToDev(`Handling logout request...`);
		await oktaAuth.signOut();
	};

	/**
	 * Clears any existing authentication errors from the context.
	 */
	const clearError: AnyAuthContext['clearError'] = useCallback(() => {
		logToDev(`Clearing error status...`);

		if (!error) return;

		setError(undefined);

		// Also reset the authentication status to "unauthenticated" since that is
		// the safest state to assume after an error.
		setStatus('unauthenticated');
	}, [error]);

	useEffect(() => {
		/**
		 * Resets or refreshes the user's data based on the current authentication
		 * status.
		 */
		async function updateStatus() {
			logToDev(`Attempting auth status update...`);

			// Remove any existing tokens marked as expired so we don't need to check
			// for them explicitly.
			oktaAuth.tokenManager.clearPendingRemoveTokens();

			if (oktaAuth.isLoginRedirect()) {
				logToDev(`Within login redirect; setting "authenticating" status.`);
				setStatus('authenticating');
				setUser(undefined);
				return;
			}

			const isAuthenticated = await oktaAuth.isAuthenticated();

			if (!isAuthenticated) {
				logToDev(`User not authenticated; setting "unauthenticated" status.`);
				setStatus('unauthenticated');
				setUser(undefined);
				return;
			}

			const accessToken: AccessToken = await oktaAuth.tokenManager.get('accessToken');
			const userInfo = await oktaAuth.token.getUserInfo(accessToken);

			logToDev(`Updating user data:`, userInfo);
			setStatus('authenticated');
			setUser(userInfo);
		}

		updateStatus();
	}, []);

	const values = useMemo(
		() => ({ status, data: { user, error }, login, register, resetPassword, loginCallback, logout, clearError }),
		[status, user, error, clearError]
	) as AnyAuthContext;

	return <authContext.Provider value={values}>{children}</authContext.Provider>;
}

// Default our context hook to an "authenticated" state since that will likely
// be the most common use case.
export const useAuth = <T extends AnyAuthContext = AuthenticatedAuthContext>() => useContext(authContext) as T;

export default AuthProvider;
