import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import isEqual from 'lodash.isequal';
import { useLocation } from 'react-router-dom';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';

import {
	ABANDONED_DAYS_TOGGLE_VALUES,
	ADD_ONS_VALUES,
	BLOCK_TIMESLOT_VALUES,
	clamp,
	convertToUTC,
	DAYS_OF_WEEK,
	DAYS_OF_WEEK_LIST,
	getShiftedStartDate,
	PRIMETIME_VALUES,
	sortDays,
	stringTimeToDate,
	TIMESLOT_TYPE_VALUES,
	UTILIZATION_TYPE_VALUES,
} from 'utils';
import {
	BlockOptionApiResponse,
	getQueryError,
	handleApiResponse,
	ShortOption,
	useApplyViewMutation,
	useAppSelector,
	useGetAnesthesiologistOptionsQuery,
	useGetAnesthetistOptionsQuery,
	useGetBlockNameOptionsQuery,
	useGetDefaultStartEndDateQuery,
	useGetEncounterTypeOptionsQuery,
	useGetProcedureOptionsQuery,
	useGetRoomOptionsQuery,
	useGetSavedFilterQuery,
	useGetServiceLineOptionsQuery,
	useGetSurgeonOptionsQuery,
	useSetFilterMutation,
	useSystem,
} from 'store';
import { Facility, Routes } from 'models';
import { AnyAuthContext, useAuth, useToast } from 'context';
import { defaultStaffingGrid } from 'pages/Anesthesia/components';

import type {
	AnesthesiologistOptionApiResponse,
	AnesthetistOptionApiResponse,
	dropDowns,
	EncounterTypeOptionApiResponse,
	NurseStaffRatio,
	refreshTagTypes,
	RoomOptionApiResponse,
	Salary,
	SavedFilter,
	SavedView,
	ServiceLineOptionApiResponse,
	StaffWindow,
	SurgeonOptionApiResponse,
} from 'store';
import type { ProviderProps } from 'react';
import { parseOAuthResponseFromUrl } from '@okta/okta-auth-js/lib/oidc/parseFromUrl';

export type FilterFieldName =
	| 'date'
	| 'dateRange'
	| 'surgeon'
	| 'surgeons'
	| 'anesthesiologists'
	| 'anesthetists'
	| 'daysOfWeek'
	| 'serviceLines'
	| 'encounterTypes'
	| 'rooms'
	| 'turnoverTimeThreshold'
	| 'fcotsGraceMinutes'
	| 'percentile'
	| 'primetime'
	| 'addOns'
	| 'utilizationType'
	| 'timeslotType'
	| 'blockTimeslot'
	| 'blockName'
	| 'blockNames'
	| 'abandonedDaysToggle'
	| 'procedures'
	| 'heatmapMethod'
	| 'salaries'
	| 'nurseStaffRatios'
	| 'mdaCoverRoom'
	| 'turnoverConcurrency'
	| 'indirectStaffWindow'
	| 'variableStaffWindow'
	| 'phase';

export interface FilterFieldState<ValueType> {
	/** The value that the user has supplied for this field, but not yet applied. */
	readonly selected: ValueType;

	/** The value that has been applied to this field and which should be used to perform any API calls. */
	readonly applied: ValueType;
}

interface SingleSelectFilterFieldState<ValueType, AllValuesType> extends FilterFieldState<ValueType> {
	readonly all: AllValuesType;
}

interface MultiSelectFilterFieldState<ValueType> extends FilterFieldState<ValueType> {
	readonly all: ValueType;
}

export interface RangeFilterFieldState extends FilterFieldState<number> {
	readonly min: number;
	readonly max: number;
	readonly step: number;
}

export interface FilterMetadata {
	title: string | undefined;
	saved_at: Date | undefined;
	is_saved_view?: boolean;
	id?: number;
	is_branched_filter?: boolean;
}

type DateState = FilterFieldState<Date>;

export type DateRangeState = FilterFieldState<{ startDate: Date; endDate: Date }>;

export type SalaryState = FilterFieldState<Salary>;

export type NurseStaffRatioState = FilterFieldState<NurseStaffRatio>;

export type StaffWindowState = FilterFieldState<StaffWindow>;

export type MDACoverRoomState = FilterFieldState<boolean>;

export type TurnoverConcurrencyState = FilterFieldState<'Included' | 'Excluded' | 'Only Turnover'>;

export type PhaseState = SingleSelectFilterFieldState<ShortOption, ShortOption[]>;

type SurgeonState = SingleSelectFilterFieldState<
	SurgeonOptionApiResponse['surgeons'][number],
	SurgeonOptionApiResponse['surgeons']
>;

export type BlockNameState = SingleSelectFilterFieldState<
	BlockOptionApiResponse['blocks'][number],
	BlockOptionApiResponse['blocks']
>;

export type HeatmapMethodState = SingleSelectFilterFieldState<ShortOption, ShortOption[]>;

export type BlockNamesState = MultiSelectFilterFieldState<BlockOptionApiResponse['blocks']>;
export type ProcedureOptionsState = MultiSelectFilterFieldState<ShortOption[]>;

export type SurgeonsState = MultiSelectFilterFieldState<SurgeonOptionApiResponse['surgeons']>;

export type AnesthesiologistsState = MultiSelectFilterFieldState<
	AnesthesiologistOptionApiResponse['anesthesiologists']
>;

export type AnesthetistsState = MultiSelectFilterFieldState<AnesthetistOptionApiResponse['anesthetists']>;

type ServiceLinesState = MultiSelectFilterFieldState<ServiceLineOptionApiResponse['service_lines']>;

type EncounterTypesState = MultiSelectFilterFieldState<EncounterTypeOptionApiResponse['encounter_types']>;

type RoomsState = MultiSelectFilterFieldState<RoomOptionApiResponse['rooms']>;

export type DaysOfWeekState = SingleSelectFilterFieldState<DAYS_OF_WEEK[], DAYS_OF_WEEK[]>;

type SVG_ExternalFilter = dropDowns;

type BlockTimeslotState = SingleSelectFilterFieldState<
	typeof BLOCK_TIMESLOT_VALUES[number][],
	typeof BLOCK_TIMESLOT_VALUES
>;

type PrimetimeState = SingleSelectFilterFieldState<typeof PRIMETIME_VALUES[number], typeof PRIMETIME_VALUES>;

export type AddOnsState = SingleSelectFilterFieldState<typeof ADD_ONS_VALUES[number], typeof ADD_ONS_VALUES>;

export type UtilizationTypeState = SingleSelectFilterFieldState<
	typeof UTILIZATION_TYPE_VALUES[number],
	typeof UTILIZATION_TYPE_VALUES
>;

export type AbandonedDaysToggleState = SingleSelectFilterFieldState<
	typeof ABANDONED_DAYS_TOGGLE_VALUES[number],
	typeof ABANDONED_DAYS_TOGGLE_VALUES
>;

type TimeslotTypeState = SingleSelectFilterFieldState<typeof TIMESLOT_TYPE_VALUES[number], typeof TIMESLOT_TYPE_VALUES>;

type TurnoverTimeThresholdState = RangeFilterFieldState;

type FcotsGraceMinutesState = RangeFilterFieldState;

type PercentileState = RangeFilterFieldState;

export type FilterContextField<StateType> = StateType & {
	/** Directly alters this field's `selected` and/or `applied` value when passed an object containing new values. */
	update: (newValue: Partial<StateType>) => void;

	/** Whether this field's `selected` value is different than its `applied` value. */
	readonly isDirty: boolean;

	/** Whether this field is currently retrieving value data from the Merlin API. */
	readonly isFetching: boolean;
};

interface FilterContextValue {
	date: FilterContextField<DateState>;
	dateRange: FilterContextField<DateRangeState>;
	surgeon: FilterContextField<SurgeonState>;
	surgeons: FilterContextField<SurgeonsState>;
	anesthesiologists: FilterContextField<AnesthesiologistsState>;
	anesthetists: FilterContextField<AnesthetistsState>;
	daysOfWeek: FilterContextField<DaysOfWeekState>;
	serviceLines: FilterContextField<ServiceLinesState>;
	encounterTypes: FilterContextField<EncounterTypesState>;
	rooms: FilterContextField<RoomsState>;
	turnoverTimeThreshold: FilterContextField<TurnoverTimeThresholdState>;
	fcotsGraceMinutes: FilterContextField<FcotsGraceMinutesState>;
	percentile: FilterContextField<PercentileState>;
	primetime: FilterContextField<PrimetimeState>;
	addOns: FilterContextField<AddOnsState>;
	utilizationType: FilterContextField<UtilizationTypeState>;
	timeslotType: FilterContextField<TimeslotTypeState>;
	blockTimeslot: FilterContextField<BlockTimeslotState>;
	page?: string;
	blockName: FilterContextField<BlockNameState>;
	blockNames: FilterContextField<BlockNamesState>;
	abandonedDaysToggle: FilterContextField<AbandonedDaysToggleState>;
	procedures: FilterContextField<ProcedureOptionsState>;
	heatmapMethod: FilterContextField<HeatmapMethodState>;
	dropDowns: FilterContextField<SVG_ExternalFilter>;
	salaries: FilterContextField<SalaryState>;
	nurseStaffRatios: FilterContextField<NurseStaffRatioState>;
	mdaCoverRoom: FilterContextField<MDACoverRoomState>;
	turnoverConcurrency: FilterContextField<TurnoverConcurrencyState>;
	indirectStaffWindow: FilterContextField<StaffWindowState>;
	variableStaffWindow: FilterContextField<StaffWindowState>;
	phase: FilterContextField<PhaseState>;

	/** Reverts the selected values of all filter fields back to their applied values, undoing any unapplied changes. */
	resetFilters: () => void;

	/** Reverts the selected and applied values of all filter fields back to their default values. */
	clearFilters: () => void;

	/** Reverts the selected and applied values of all filter fields back to their initial values. */
	reloadFilters: () => void;

	/** Replaces the applied values of all filter fields with their current selected values. */
	applyFilters: () => void;

	/** Update current view */
	updateSavedView: () => void;

	// ** Saves drop down state */
	saveDropdown: (newState: dropDowns, refreshTag?: typeof refreshTagTypes) => void;

	// ** Save the current view */
	saveCurrentView: (name: string, notes: string | undefined, is_trailing_date_range: boolean | undefined) => void;

	// ** Save the current view */
	applyNewView: (
		filter_id: number,
		set_is_public?: boolean | undefined,
		set_is_archived?: boolean | undefined,
		method?: string
	) => void;

	// ** Status for set current view */
	setFilterStatus: QueryStatus;

	/** Whether any of the filters currently have selected values that differ from their applied values. */
	filtersAreDirty: boolean;

	/** Whether any of the filters are currently retrieving data from the Merlin API. */
	filtersAreFetching: boolean;

	/** If saved filter, stores title and saved_at */
	metadata: FilterMetadata;

	currentPageLoaded: string | boolean;
	/** max surgery date for datepicker shortcuts */
	endDate: Date;

	/** min surgery date for datepicker shortcuts */
	startDate: Date;

	/** min surgery date for which we have data for*/
	earliest_date?: Date;
}

type FilterProviderProps = Omit<ProviderProps<FilterContextValue>, 'value'>;

// Initialize the context with an empty value. We can safely cast it to the
// appropriate type since this should never be accessible externally.
const FilterContext = createContext<FilterContextValue>({} as FilterContextValue);

/** Provides access to common filter states throughout the app. */
export function FilterProvider({ children }: FilterProviderProps) {
	const { pathname } = useLocation();
	const [setSavedFilter, { status: setFilterStatus }] = useSetFilterMutation();
	const [applyView] = useApplyViewMutation();
	const toast = useToast();
	const auth = useAuth<AnyAuthContext>();

	// we sometimes pass arguments through the URL
	const url = new URL(window.location.href);
	const params = new URLSearchParams(url.search);

	// Identify the current healthsystem and pull in any healthsystem-specific
	// data to be used with the filters.
	const { data: facility_data } = useSystem();
	const { selectedSystem, selectedFacility } = useAppSelector((state) => state.userState);

	const currentFacility: Facility | undefined = useMemo(
		() => facility_data?.facilities?.filter((f) => f.id === selectedFacility)[0],
		[facility_data?.facilities, selectedFacility]
	);

	// Used to get saved filters from server
	const { data: savedFilterResponse, isFetching: isFetchingSavedFilter } = useGetSavedFilterQuery(
		{
			facility_id: selectedFacility,
			page: pathname,
		},
		{
			skip: !selectedFacility || auth.status !== 'authenticated',
		}
	);

	const savedFilter = savedFilterResponse?.filters ? savedFilterResponse.filters : undefined;

	const [metadata, setMetadata] = useState({} as FilterMetadata);
	useEffect(() => {
		setMetadata({
			title:
				savedFilter?.name ??
				(savedFilter?.notes?.includes('"parent_id"') ? JSON.parse(savedFilter?.notes)['temp_name'] : 'Filters'), //  use temp name if possible
			saved_at: savedFilter?.updated_at,
			is_saved_view: !!savedFilter?.name,
			id: savedFilter?.id,
			is_branched_filter: savedFilter?.notes?.includes('"parent_id"'), // if this id is present, it is branched
		});
	}, [savedFilter?.id, savedFilter?.name, savedFilter?.notes, savedFilter?.updated_at]);

	const { data: defaultDateRange, isFetching: isFetchingDateRange } = useGetDefaultStartEndDateQuery(
		{
			facility_id: selectedFacility,
		},
		{
			skip: !selectedFacility || auth.status !== 'authenticated',
		}
	);
	const startDate = useMemo(
		() => (defaultDateRange?.start_date ? new Date(defaultDateRange.start_date) : new Date()),
		[defaultDateRange]
	);
	const endDate = useMemo(
		() => (defaultDateRange?.end_date ? new Date(defaultDateRange.end_date) : new Date()),
		[defaultDateRange]
	);
	const earliest_date = useMemo(
		() => (defaultDateRange?.earliest_date ? new Date(defaultDateRange.earliest_date) : new Date()),
		[defaultDateRange]
	);

	const { data: surgeonData, isFetching: isFetchingSurgeons } = useGetSurgeonOptionsQuery(
		{
			facility_id: selectedFacility,
			healthsystem_id: selectedSystem,
		},
		{
			skip: !selectedFacility || auth.status !== 'authenticated',
		}
	);
	const allSurgeons = useMemo(() => surgeonData?.surgeons ?? [], [surgeonData]);

	const { data: anesthesiologistData, isFetching: isFetchingAnesthesiologists } = useGetAnesthesiologistOptionsQuery(
		{
			facility_id: selectedFacility,
			healthsystem_id: selectedSystem,
		},
		{
			skip: !selectedFacility || auth.status !== 'authenticated',
		}
	);
	const allAnesthesiologists = useMemo(() => anesthesiologistData?.anesthesiologists ?? [], [anesthesiologistData]);

	const { data: anesthetistData, isFetching: isFetchingAnesthetists } = useGetAnesthetistOptionsQuery(
		{
			facility_id: selectedFacility,
			healthsystem_id: selectedSystem,
		},
		{
			skip: !selectedFacility || auth.status !== 'authenticated',
		}
	);
	const allAnesthetists = useMemo(() => anesthetistData?.anesthetists ?? [], [anesthetistData]);

	const { data: serviceLinesData, isFetching: isFetchingServiceLines } = useGetServiceLineOptionsQuery(
		{
			facility_id: selectedFacility,
		},
		{
			skip: !selectedFacility || auth.status !== 'authenticated',
		}
	);
	const allServiceLines = useMemo(() => serviceLinesData?.service_lines ?? [], [serviceLinesData]);

	const { data: encounterTypesData, isFetching: isFetchingEncounterTypes } = useGetEncounterTypeOptionsQuery(
		{
			facility_id: selectedFacility,
		},
		{
			skip: !selectedFacility || auth.status !== 'authenticated',
		}
	);
	const allEncounterTypes = useMemo(() => encounterTypesData?.encounter_types ?? [], [encounterTypesData]);

	const { data: roomsData, isFetching: isFetchingRooms } = useGetRoomOptionsQuery(
		{ facility_id: selectedFacility },
		{
			skip: !selectedFacility || auth.status !== 'authenticated',
		}
	);
	const allRooms = useMemo(() => roomsData?.rooms ?? [], [roomsData]);

	const currentPageLoaded = useMemo(
		() => (savedFilterResponse?.page === pathname ? pathname : false),
		[pathname, savedFilterResponse]
	);

	const { data: blockNameData, isFetching: isFetchingBlockNames } = useGetBlockNameOptionsQuery(
		{
			healthsystem_id: selectedSystem,
			facility_id: selectedFacility,
		},
		{
			skip: !selectedFacility || auth.status !== 'authenticated',
		}
	);

	const allBlockNames = useMemo(() => blockNameData?.blocks ?? [], [blockNameData]);

	const { data: procedureOptionsData, isFetching: isFetchingProcedureOptions } = useGetProcedureOptionsQuery(
		{
			facility_id: selectedFacility,
		},
		{
			skip: !selectedFacility || auth.status !== 'authenticated',
		}
	);
	const allProcedureOptions = useMemo(() => procedureOptionsData?.procedures ?? [], [procedureOptionsData]);

	// Boolean for facility utilization filter
	const onFacilityUtilizationPage = pathname === '/facility-utilization';
	const activeDays = useMemo(() => {
		const days: DAYS_OF_WEEK[] = [];
		[
			'sunday_rooms_count',
			'monday_rooms_count',
			'tuesday_rooms_count',
			'wednesday_rooms_count',
			'thursday_rooms_count',
			'friday_rooms_count',
			'saturday_rooms_count',
		].forEach((room_count, i) => {
			const isActiveDay = !!currentFacility?.[room_count as keyof typeof currentFacility];
			if (isActiveDay) {
				days.push(DAYS_OF_WEEK_LIST[i]);
			}
		});
		return days;
	}, [currentFacility]);

	const initialSVGDropDown = useMemo<SVG_ExternalFilter>(
		() => ({
			viewBy: {
				label: savedFilter?.dropdowns?.viewBy.label ?? 'undefined',
				value: savedFilter?.dropdowns?.viewBy.value ?? 'undefined',
			},
			groupBy: {
				label: savedFilter?.dropdowns?.groupBy.label ?? 'undefined',
				value: savedFilter?.dropdowns?.groupBy.value ?? 'undefined',
			},
			sortBy: {
				label: savedFilter?.dropdowns?.sortBy.label ?? 'undefined',
				value: savedFilter?.dropdowns?.sortBy.value ?? 'undefined',
			},
			mode: {
				label: savedFilter?.dropdowns?.mode?.label ?? 'undefined',
				value: savedFilter?.dropdowns?.mode?.value ?? 'undefined',
			},
			detailGroupBy: {
				label: savedFilter?.dropdowns?.detailGroupBy?.label ?? 'undefined',
				value: savedFilter?.dropdowns?.detailGroupBy?.value ?? 'undefined',
			},
			detailViewBy: {
				label: savedFilter?.dropdowns?.detailViewBy?.label ?? 'undefined',
				value: savedFilter?.dropdowns?.detailViewBy?.value ?? 'undefined',
			},
			medical_ratio: savedFilter?.dropdowns?.medical_ratio ?? 3,
			eight_hour_shift: savedFilter?.dropdowns?.eight_hour_shift ?? 'true',
			ten_hour_shift: savedFilter?.dropdowns?.ten_hour_shift ?? 'true',
			twelve_hour_shift: savedFilter?.dropdowns?.twelve_hour_shift ?? 'true',
			fourteen_hour_shift: savedFilter?.dropdowns?.fourteen_hour_shift ?? 'false',
			carousel_day_of_week_selected: savedFilter?.dropdowns?.carousel_day_of_week_selected ?? 'Sunday',
			staffing_grid: savedFilter?.dropdowns?.staffing_grid ?? currentFacility?.draw_down_schedule ?? defaultStaffingGrid,
			legend: {
				heatmap: savedFilter?.dropdowns?.legend?.heatmap ?? true,
				ideal: savedFilter?.dropdowns?.legend?.ideal ?? true,
				custom: savedFilter?.dropdowns?.legend?.custom ?? false,
			},
		}),
		[savedFilter?.dropdowns, currentFacility?.draw_down_schedule]
	);

	const initialSalaries = useMemo<SalaryState>(
		() => ({
			selected: {
				anesthesiologist: savedFilter?.salaries?.anesthesiologist ?? 600000,
				anesthetist: savedFilter?.salaries?.anesthetist ?? 250000,
				registered_nurse: savedFilter?.salaries?.registered_nurse ?? 100000,
				surgical_tech: savedFilter?.salaries?.surgical_tech ?? 56000,
				variable_staff: savedFilter?.salaries?.variable_staff ?? 80000,
				indirect_staff: savedFilter?.salaries?.indirect_staff ?? 30000,
			},
			applied: {
				anesthesiologist: savedFilter?.salaries?.anesthesiologist ?? 600000,
				anesthetist: savedFilter?.salaries?.anesthetist ?? 250000,
				registered_nurse: savedFilter?.salaries?.registered_nurse ?? 100000,
				surgical_tech: savedFilter?.salaries?.surgical_tech ?? 56000,
				variable_staff: savedFilter?.salaries?.variable_staff ?? 80000,
				indirect_staff: savedFilter?.salaries?.indirect_staff ?? 30000,
			},
		}),
		[savedFilter?.salaries]
	);

	const initialMDACoverRoom = useMemo<MDACoverRoomState>(
		() => ({
			selected: savedFilter?.mda_cover_room ?? true,
			applied: savedFilter?.mda_cover_room ?? true,
		}),
		[savedFilter?.mda_cover_room]
	);

	const initialTurnoverConcurrency = useMemo<TurnoverConcurrencyState>(
		() => ({
			selected: savedFilter?.turnover_concurrency ?? 'Included',
			applied: savedFilter?.turnover_concurrency ?? 'Included',
		}),
		[savedFilter?.turnover_concurrency]
	);

	// DOW can change depending on facility settings
	const initialDaysOfWeek = useMemo<DaysOfWeekState>(
		() => ({
			all: onFacilityUtilizationPage ? activeDays : [...DAYS_OF_WEEK_LIST],
			selected: savedFilter?.days_of_week
				? onFacilityUtilizationPage
					? savedFilter?.days_of_week.filter((day) => activeDays.includes(day))
					: savedFilter?.days_of_week
				: onFacilityUtilizationPage
				? activeDays
				: [...DAYS_OF_WEEK_LIST],
			applied: savedFilter?.days_of_week
				? onFacilityUtilizationPage
					? savedFilter?.days_of_week.filter((day) => activeDays.includes(day))
					: savedFilter?.days_of_week
				: onFacilityUtilizationPage
				? activeDays
				: [...DAYS_OF_WEEK_LIST],
		}),
		[activeDays, onFacilityUtilizationPage, savedFilter?.days_of_week]
	);

	const initialBlockTimeslot = useMemo<BlockTimeslotState>(
		() => ({
			all: BLOCK_TIMESLOT_VALUES,
			selected: savedFilter?.block_time_slot ?? [...BLOCK_TIMESLOT_VALUES],
			applied: savedFilter?.block_time_slot ?? [...BLOCK_TIMESLOT_VALUES],
		}),
		[savedFilter?.block_time_slot]
	);

	const initialTurnoverTimeThreshold = useMemo<TurnoverTimeThresholdState>(
		() => ({
			selected: savedFilter?.turnover_upper_bound ?? 60,
			applied: savedFilter?.turnover_upper_bound ?? 60,
			min: 30,
			max: 120,
			step: 5,
		}),
		[savedFilter?.turnover_upper_bound]
	);

	const initialFcotsGraceMinutes = useMemo<FcotsGraceMinutesState>(
		() => ({
			selected: savedFilter?.fcots_grace ?? 0,
			applied: savedFilter?.fcots_grace ?? 0,
			min: 0,
			max: 30,
			step: 1,
		}),
		[savedFilter?.fcots_grace]
	);

	const initialPercentile = useMemo<PercentileState>(
		() => ({
			selected: savedFilter?.percentile ?? 95,
			applied: savedFilter?.percentile ?? 95,
			min: 50,
			max: 100,
			step: 5,
		}),
		[savedFilter?.percentile]
	);

	const initialNurseStaffRatios = useMemo<NurseStaffRatioState>(
		() => ({
			selected: {
				variable: savedFilter?.nurse_staff_ratios?.variable ?? 0.5,
				indirect: savedFilter?.nurse_staff_ratios?.indirect ?? 0.33,
			},
			applied: {
				variable: savedFilter?.nurse_staff_ratios?.variable ?? 0.5,
				indirect: savedFilter?.nurse_staff_ratios?.indirect ?? 0.33,
			},
		}),
		[savedFilter?.nurse_staff_ratios]
	);

	const initialIndirectStaffWindow = useMemo<StaffWindowState>(
		() => ({
			selected: {
				start: savedFilter?.indirect_staff_window?.start ?? '7:00:00',
				end: savedFilter?.indirect_staff_window?.end ?? '17:00:00',
			},
			applied: {
				start: savedFilter?.indirect_staff_window?.start ?? '7:00:00',
				end: savedFilter?.indirect_staff_window?.end ?? '17:00:00',
			},
		}),
		[savedFilter?.indirect_staff_window]
	);

	const initialVariableStaffWindow = useMemo<StaffWindowState>(
		() => ({
			selected: {
				start: savedFilter?.variable_staff_window?.start ?? '7:00:00',
				end: savedFilter?.variable_staff_window?.end ?? '17:00:00',
			},
			applied: {
				start: savedFilter?.variable_staff_window?.start ?? '7:00:00',
				end: savedFilter?.variable_staff_window?.end ?? '17:00:00',
			},
		}),
		[savedFilter?.variable_staff_window]
	);

	const initialPrimetime = useMemo<PrimetimeState>(
		() =>
			pathname === Routes.SURGICAL_VOLUME
				? {
						all: PRIMETIME_VALUES,
						selected: savedFilter?.primetime ?? 'All',
						applied: savedFilter?.primetime ?? 'All',
				  }
				: {
						all: PRIMETIME_VALUES,
						selected: savedFilter?.primetime ?? 'Primetime',
						applied: savedFilter?.primetime ?? 'Primetime',
				  },
		[savedFilter?.primetime, pathname]
	);

	const initialAddOns = useMemo<AddOnsState>(
		() => ({
			all: ADD_ONS_VALUES,
			selected: savedFilter?.add_ons ?? 'All',
			applied: savedFilter?.add_ons ?? 'All',
		}),
		[savedFilter?.add_ons]
	);

	const initialUtilizationType = useMemo<UtilizationTypeState>(
		() => ({
			all: UTILIZATION_TYPE_VALUES,
			selected: savedFilter?.utilization_type ?? 'Adjusted',
			applied: savedFilter?.utilization_type ?? 'Adjusted',
		}),
		[savedFilter?.utilization_type]
	);

	const initialAbandonedDaysToggle = useMemo<AbandonedDaysToggleState>(
		() => ({
			all: ABANDONED_DAYS_TOGGLE_VALUES,
			selected: savedFilter?.abandoned_days_toggle ?? 'Included',
			applied: savedFilter?.abandoned_days_toggle ?? 'Included',
		}),
		[savedFilter?.abandoned_days_toggle]
	);

	const initialTimeslotType = useMemo<TimeslotTypeState>(
		() => ({
			all: TIMESLOT_TYPE_VALUES,
			selected: savedFilter?.block_type ?? 'Scheduled',
			applied: savedFilter?.block_type ?? 'Scheduled',
		}),
		[savedFilter?.block_type]
	);

	let preselected_date: string | Date | null = params.get('preselected_date');
	if (preselected_date !== null) {
		const [month, day, year] = preselected_date.split('/').map(Number);

		// Note that JavaScript's Date object uses zero-based indexing for months (0 = January, 11 = December)
		preselected_date = new Date(year, month - 1, day);
	}

	// Helper function to convert string to date if needed
	const parsePreselectedDate = (preselected_date: string | Date | null): Date | null => {
		if (typeof preselected_date === 'string') {
			const [month, day, year] = preselected_date.split('/').map(Number);
			return new Date(year, month - 1, day); // JavaScript's Date object uses zero-based indexing for months
		}
		if (preselected_date instanceof Date) {
			return preselected_date;
		}
		return null;
	};

	// Usage within the component
	const preselectedDate: Date | null = parsePreselectedDate(params.get('preselected_date'));

	const initialDate = useMemo<DateState>(() => {
		if (preselectedDate) {
			return { selected: preselectedDate, applied: preselectedDate };
		}

		const savedFilterDate = savedFilter?.date ? new Date(savedFilter.date) : null;
		const trailingEndDate = savedFilter?.is_trailing_date_range ? endDate : savedFilterDate || endDate;

		return {
			selected: trailingEndDate,
			applied: trailingEndDate,
		};
	}, [endDate, savedFilter?.date, savedFilter?.is_trailing_date_range]);

	const initialDateRange = useMemo<DateRangeState>(() => {
		if (preselectedDate) {
			return {
				selected: { startDate: preselectedDate, endDate: preselectedDate },
				applied: { startDate: preselectedDate, endDate: preselectedDate },
			};
		}

		const savedStartDate = savedFilter?.date_range_start ? new Date(savedFilter.date_range_start) : null;
		const trailingStartDate =
			savedFilter?.is_trailing_date_range && savedStartDate
				? getShiftedStartDate(
						savedStartDate,
						savedFilter?.date_range_end ? new Date(savedFilter.date_range_end) : new Date(),
						endDate
				  )
				: savedStartDate ?? startDate;

		const selectedEndDate = savedFilter?.date_range_end
			? savedFilter.is_trailing_date_range
				? endDate
				: new Date(savedFilter.date_range_end)
			: endDate;

		return {
			selected: {
				startDate: trailingStartDate,
				endDate: selectedEndDate,
			},
			applied: {
				startDate: trailingStartDate,
				endDate: selectedEndDate,
			},
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		startDate,
		endDate,
		savedFilter?.date_range_start,
		savedFilter?.date_range_end,
		savedFilter?.is_trailing_date_range,
	]);

	const initialSurgeon = useMemo<SurgeonState>(
		() => ({
			all: allSurgeons,
			selected: savedFilter?.surgeon ?? { id: 0, name: '' },
			applied: savedFilter?.surgeon ?? { id: 0, name: '' },
		}),
		[allSurgeons, savedFilter?.surgeon]
	);

	const initialHeatmapMethod = useMemo<HeatmapMethodState>(
		() => ({
			all: [
				{ id: 1, name: 'Percentile' },
				{ id: 2, name: 'Average' },
				{ id: 3, name: 'Excalibur' },
			],
			selected: savedFilter?.heatmap_method ?? { id: 1, name: 'Percentile' },
			applied: savedFilter?.heatmap_method ?? { id: 1, name: 'Percentile' },
		}),
		[savedFilter?.heatmap_method]
	);

	const initialPhase = useMemo<PhaseState>(
		() => ({
			all: [
				{ id: 1, name: 'Preop' },
				{ id: 2, name: 'Intraop' },
				{ id: 3, name: 'PACU Phase I' },
				{ id: 4, name: 'PACU Phase II' },
			],
			selected: savedFilter?.phase ?? { id: 2, name: 'Intraop' },
			applied: savedFilter?.phase ?? { id: 2, name: 'Intraop' },
		}),
		[savedFilter?.phase]
	);

	const initialBlockName = useMemo<BlockNameState>(
		() => ({
			all: allBlockNames,
			selected: allBlockNames.find((b) => b.id === (savedFilter?.block ? savedFilter?.block.id : 0)) ?? {
				id: 0,
				name: '',
			},
			applied: allBlockNames.find((b) => b.id === (savedFilter?.block ? savedFilter?.block.id : 0)) ?? { id: 0, name: '' },
		}),
		[allBlockNames, savedFilter?.block]
	);

	const preselectedBlockName = params.get('block_scorecard_id') ? parseInt(params.get('block_scorecard_id') ?? '0') : 0;
	const initialBlockNames = useMemo<BlockNamesState>(
		() => ({
			all: allBlockNames,
			selected:
				preselectedBlockName !== 0
					? allBlockNames.filter(({ id }) => id === preselectedBlockName)
					: savedFilter?.blocks
					? allBlockNames.filter(({ id }) => (savedFilter?.blocks ? savedFilter.blocks : []).find((d) => d.id === id))
					: pathname === '/block-scorecard'
					? []
					: allBlockNames,
			applied:
				preselectedBlockName !== 0
					? allBlockNames.filter(({ id }) => id === preselectedBlockName)
					: savedFilter?.blocks
					? allBlockNames.filter(({ id }) => (savedFilter?.blocks ? savedFilter.blocks : []).find((d) => d.id === id))
					: pathname === '/block-scorecard'
					? []
					: allBlockNames,
		}),
		[allBlockNames, pathname, preselectedBlockName, savedFilter?.blocks]
	);

	const initialSurgeons = useMemo<SurgeonsState>(
		() => ({
			all: allSurgeons,
			selected: savedFilter?.surgeons ? savedFilter?.surgeons : pathname === '/surgeon-scorecard' ? [] : allSurgeons,
			applied: savedFilter?.surgeons ? savedFilter?.surgeons : pathname === '/surgeon-scorecard' ? [] : allSurgeons,
		}),
		[allSurgeons, pathname, savedFilter?.surgeons]
	);

	const initialAnesthesiologists = useMemo<AnesthesiologistsState>(
		() => ({
			all: allAnesthesiologists,
			selected: savedFilter?.anesthesiologists ?? allAnesthesiologists,
			applied: savedFilter?.anesthesiologists ?? allAnesthesiologists,
		}),
		[allAnesthesiologists, savedFilter?.anesthesiologists]
	);

	const initialAnesthetists = useMemo<AnesthetistsState>(
		() => ({
			all: allAnesthetists,
			selected: savedFilter?.anesthetists ?? allAnesthetists,
			applied: savedFilter?.anesthetists ?? allAnesthetists,
		}),
		[allAnesthetists, savedFilter?.anesthetists]
	);

	const initialServiceLines = useMemo<ServiceLinesState>(
		() => ({
			all: allServiceLines,
			selected: savedFilter?.service_lines ?? allServiceLines,
			applied: savedFilter?.service_lines ?? allServiceLines,
		}),
		[allServiceLines, savedFilter?.service_lines]
	);

	const initialEncounterTypes = useMemo<EncounterTypesState>(
		() => ({
			all: allEncounterTypes,
			selected: savedFilter?.encounter_types ?? allEncounterTypes,
			applied: savedFilter?.encounter_types ?? allEncounterTypes,
		}),
		[allEncounterTypes, savedFilter?.encounter_types]
	);

	const initialRooms = useMemo<RoomsState>(
		() => ({
			all: allRooms,
			selected: savedFilter?.rooms ?? allRooms,
			applied: savedFilter?.rooms ?? allRooms,
		}),
		[allRooms, savedFilter?.rooms]
	);

	const initialProcedureOptions = useMemo<ProcedureOptionsState>(
		() => ({
			all: allProcedureOptions.map((value, index) => ({ id: index + 1, name: value })),
			selected: savedFilter?.procedures?.map((value, index) => ({ id: index + 1, name: value })) ?? [
				{ id: 1, name: 'robot' },
			],
			applied: savedFilter?.procedures?.map((value, index) => ({ id: index + 1, name: value })) ?? [
				{ id: 1, name: 'robot' },
			],
		}),
		[allProcedureOptions, savedFilter?.procedures]
	);

	// Define reducers for updating the values of all filters.
	const [date, updateDate] = useReducer(
		(oldState: DateState, newState: Partial<DateState>) => ({
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialDate
	);

	const [dropDowns, updateSVGDropDown] = useReducer(
		(oldState: SVG_ExternalFilter, newState: Partial<SVG_ExternalFilter>): dropDowns => ({
			viewBy: {
				label: newState.viewBy?.label ?? oldState.viewBy.label,
				value: newState.viewBy?.value ?? oldState.viewBy.value,
			},
			groupBy: {
				label: newState.groupBy?.label ?? oldState.groupBy.label,
				value: newState.groupBy?.value ?? oldState.groupBy.value,
			},
			sortBy: {
				label: newState.sortBy?.label ?? oldState.sortBy.label,
				value: newState.sortBy?.value ?? oldState.sortBy.value,
			},
			mode: {
				label: newState.mode?.label ?? oldState.mode.label,
				value: newState.mode?.value ?? oldState.mode.value,
			},
			detailViewBy: {
				label: newState.detailViewBy?.label ?? oldState.detailViewBy?.label ?? 'undefined',
				value: newState.detailViewBy?.value ?? oldState.detailViewBy?.value ?? 'undefined',
			},
			detailGroupBy: {
				label: newState.detailGroupBy?.label ?? oldState.detailGroupBy?.label ?? 'undefined',
				value: newState.detailGroupBy?.value ?? oldState.detailGroupBy?.value ?? 'undefined',
			},
			medical_ratio: newState.medical_ratio ?? oldState.medical_ratio,
			eight_hour_shift: newState.eight_hour_shift ?? oldState.eight_hour_shift,
			ten_hour_shift: newState.ten_hour_shift ?? oldState.ten_hour_shift,
			twelve_hour_shift: newState.twelve_hour_shift ?? oldState.twelve_hour_shift,
			fourteen_hour_shift: newState.fourteen_hour_shift ?? oldState.fourteen_hour_shift,
			carousel_day_of_week_selected: newState.carousel_day_of_week_selected ?? oldState.carousel_day_of_week_selected,
			staffing_grid: newState.staffing_grid ?? oldState.staffing_grid,
			legend: newState.legend ?? oldState.legend,
		}),
		initialSVGDropDown
	);

	const [salaries, updateSalaries] = useReducer(
		(oldState: SalaryState, newState: Partial<SalaryState>) => ({
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialSalaries
	);

	const [nurseStaffRatios, updateNurseStaffRatios] = useReducer(
		(oldState: NurseStaffRatioState, newState: Partial<NurseStaffRatioState>) => ({
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialNurseStaffRatios
	);

	const [indirectStaffWindow, updateIndirectStaffWindow] = useReducer(
		(oldState: StaffWindowState, newState: Partial<StaffWindowState>) => ({
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialIndirectStaffWindow
	);

	const [variableStaffWindow, updateVariableStaffWindow] = useReducer(
		(oldState: StaffWindowState, newState: Partial<StaffWindowState>) => ({
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialVariableStaffWindow
	);

	const [mdaCoverRoom, updateMDACoverRoom] = useReducer(
		(oldState: MDACoverRoomState, newState: Partial<MDACoverRoomState>) => ({
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialMDACoverRoom
	);

	const [turnoverConcurrency, updateTurnoverConcurrency] = useReducer(
		(oldState: TurnoverConcurrencyState, newState: Partial<TurnoverConcurrencyState>) => ({
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialTurnoverConcurrency
	);

	const [dateRange, updateDateRange] = useReducer(
		(oldState: DateRangeState, newState: Partial<DateRangeState>) => ({
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialDateRange
	);

	const [surgeon, updateSurgeon] = useReducer(
		(oldState: SurgeonState, newState: Partial<SurgeonState>) => ({
			all: newState.all ?? oldState.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialSurgeon
	);

	const [blockName, updateBlockName] = useReducer(
		(oldState: BlockNameState, newState: Partial<BlockNameState>) => ({
			all: newState.all ?? oldState.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialBlockName
	);

	const [blockNames, updateBlockNames] = useReducer(
		(oldState: BlockNamesState, newState: Partial<BlockNamesState>) => ({
			all: newState.all ?? oldState.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialBlockNames
	);

	const [procedures, updateProcedures] = useReducer(
		(oldState: ProcedureOptionsState, newState: Partial<ProcedureOptionsState>) => ({
			all: newState.all ?? oldState.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialProcedureOptions
	);

	const [heatmapMethod, updateHeatmapMethod] = useReducer(
		(oldState: HeatmapMethodState, newState: Partial<HeatmapMethodState>) => ({
			all: newState.all ?? oldState.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialHeatmapMethod
	);

	const [surgeons, updateSurgeons] = useReducer(
		(oldState: SurgeonsState, newState: Partial<SurgeonsState>) => ({
			all: newState.all ?? oldState.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialSurgeons
	);

	const [anesthesiologists, updateAnesthesiologists] = useReducer(
		(oldState: AnesthesiologistsState, newState: Partial<AnesthesiologistsState>) => ({
			all: newState.all ?? oldState.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialAnesthesiologists
	);

	const [anesthetists, updateAnesthetists] = useReducer(
		(oldState: AnesthetistsState, newState: Partial<AnesthetistsState>) => ({
			all: newState.all ?? oldState.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialAnesthetists
	);

	const [daysOfWeek, updateDaysOfWeek] = useReducer(
		(oldState: DaysOfWeekState, newState: Partial<DaysOfWeekState>) => ({
			all: initialDaysOfWeek.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialDaysOfWeek
	);

	const [serviceLines, updateServiceLines] = useReducer(
		(oldState: ServiceLinesState, newState: Partial<ServiceLinesState>) => ({
			all: newState.all ?? oldState.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialServiceLines
	);

	const [encounterTypes, updateEncounterTypes] = useReducer(
		(oldState: EncounterTypesState, newState: Partial<EncounterTypesState>) => ({
			all: newState.all ?? oldState.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialEncounterTypes
	);

	const [rooms, updateRooms] = useReducer(
		(oldState: RoomsState, newState: Partial<RoomsState>) => ({
			all: newState.all ?? oldState.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialRooms
	);

	const [turnoverTimeThreshold, updateTurnoverTimeThreshold] = useReducer(
		(oldState: TurnoverTimeThresholdState, newState: Partial<TurnoverTimeThresholdState>) => ({
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
			min: initialTurnoverTimeThreshold.min,
			max: initialTurnoverTimeThreshold.max,
			step: initialTurnoverTimeThreshold.step,
		}),
		initialTurnoverTimeThreshold
	);

	const [fcotsGraceMinutes, updateFcotsGraceMinutes] = useReducer(
		(oldState: FcotsGraceMinutesState, newState: Partial<FcotsGraceMinutesState>) => ({
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
			min: initialFcotsGraceMinutes.min,
			max: initialFcotsGraceMinutes.max,
			step: initialFcotsGraceMinutes.step,
		}),
		initialFcotsGraceMinutes
	);

	const [percentile, updatePercentile] = useReducer(
		(oldState: PercentileState, newState: Partial<PercentileState>) => ({
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
			min: initialPercentile.min,
			max: initialPercentile.max,
			step: initialPercentile.step,
		}),
		initialPercentile
	);

	const [primetime, updatePrimetime] = useReducer(
		(oldState: PrimetimeState, newState: Partial<PrimetimeState>) => ({
			all: initialPrimetime.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialPrimetime
	);

	const [addOns, updateAddOns] = useReducer(
		(oldState: AddOnsState, newState: Partial<AddOnsState>) => ({
			all: initialAddOns.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialAddOns
	);

	const [utilizationType, updateUtilizationType] = useReducer(
		(oldState: UtilizationTypeState, newState: Partial<UtilizationTypeState>) => ({
			all: initialUtilizationType.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialUtilizationType
	);

	const [abandonedDaysToggle, updateAbandonedDaysToggle] = useReducer(
		(oldState: AbandonedDaysToggleState, newState: Partial<AbandonedDaysToggleState>) => ({
			all: initialAbandonedDaysToggle.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialAbandonedDaysToggle
	);

	const [timeslotType, updateTimeslotType] = useReducer(
		(oldState: TimeslotTypeState, newState: Partial<TimeslotTypeState>) => ({
			all: initialTimeslotType.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialTimeslotType
	);

	const [phase, updatePhase] = useReducer(
		(oldState: PhaseState, newState: Partial<PhaseState>) => ({
			all: initialPhase.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialPhase
	);

	const [blockTimeslot, updateBlockTimeslot] = useReducer(
		(oldState: BlockTimeslotState, newState: Partial<BlockTimeslotState>) => ({
			all: initialBlockTimeslot.all,
			selected: newState.selected ?? oldState.selected,
			applied: newState.applied ?? oldState.applied,
		}),
		initialBlockTimeslot
	);

	const is_current_filter_view = metadata?.is_saved_view;

	// Define any functions to pass as part of the context. These are documented
	// within the context's type.
	const resetFilters = () => {
		updateDate({ selected: date.applied });
		updateDateRange({ selected: dateRange.applied });
		updateSurgeon({ selected: surgeon.applied });
		updateSurgeons({ selected: surgeons.applied });
		updateAnesthesiologists({ selected: anesthesiologists.applied });
		updateAnesthetists({ selected: anesthetists.applied });
		updateDaysOfWeek({ selected: daysOfWeek.applied });
		updateServiceLines({ selected: serviceLines.applied });
		updateEncounterTypes({ selected: encounterTypes.applied });
		updateRooms({ selected: rooms.applied });
		updateTurnoverTimeThreshold({ selected: turnoverTimeThreshold.applied });
		updateFcotsGraceMinutes({ selected: fcotsGraceMinutes.applied });
		updatePercentile({ selected: percentile.applied });
		updatePrimetime({ selected: primetime.applied });
		updateAddOns({ selected: addOns.applied });
		updateUtilizationType({ selected: utilizationType.applied });
		updateAbandonedDaysToggle({ selected: abandonedDaysToggle.applied });
		updateTimeslotType({ selected: timeslotType.applied });
		updateBlockTimeslot({ selected: blockTimeslot.applied });
		updateBlockName({ selected: blockName.applied });
		updateBlockNames({ selected: blockNames.applied });
		updateProcedures({ selected: procedures.applied });
		updateSVGDropDown(dropDowns);
		updateHeatmapMethod({ selected: heatmapMethod.applied });
		updateSalaries({ selected: salaries.applied });
		updateNurseStaffRatios({ selected: nurseStaffRatios.applied });
		updateMDACoverRoom({ selected: mdaCoverRoom.applied });
		updateTurnoverConcurrency({ selected: turnoverConcurrency.applied });
		updateIndirectStaffWindow({ selected: indirectStaffWindow.applied });
		updateVariableStaffWindow({ selected: variableStaffWindow.applied });
		updatePhase({ selected: phase.applied });
	};

	const reloadFilters = useCallback(() => {
		updateDate(initialDate);
		updateDateRange(initialDateRange);
		updateSurgeon(initialSurgeon);
		updateSurgeons(initialSurgeons);
		updateAnesthesiologists(initialAnesthesiologists);
		updateAnesthetists(initialAnesthetists);
		updateDaysOfWeek(initialDaysOfWeek);
		updateServiceLines(initialServiceLines);
		updateEncounterTypes(initialEncounterTypes);
		updateRooms(initialRooms);
		updateTurnoverTimeThreshold(initialTurnoverTimeThreshold);
		updateFcotsGraceMinutes(initialFcotsGraceMinutes);
		updatePercentile(initialPercentile);
		updatePrimetime(initialPrimetime);
		updateAddOns(initialAddOns);
		updateUtilizationType(initialUtilizationType);
		updateAbandonedDaysToggle(initialAbandonedDaysToggle);
		updateTimeslotType(initialTimeslotType);
		updateBlockTimeslot(initialBlockTimeslot);
		updateBlockName(initialBlockName);
		updateBlockNames(initialBlockNames);
		updateProcedures(initialProcedureOptions);
		updateSVGDropDown(initialSVGDropDown);
		updateHeatmapMethod(initialHeatmapMethod);
		updateSalaries(initialSalaries);
		updateNurseStaffRatios(initialNurseStaffRatios);
		updateMDACoverRoom(initialMDACoverRoom);
		updateTurnoverConcurrency(initialTurnoverConcurrency);
		updateIndirectStaffWindow(initialIndirectStaffWindow);
		updateVariableStaffWindow(initialVariableStaffWindow);
		updatePhase(initialPhase);
	}, [
		initialDate,
		initialDateRange,
		initialSurgeon,
		initialSurgeons,
		initialAnesthesiologists,
		initialAnesthetists,
		initialDaysOfWeek,
		initialServiceLines,
		initialEncounterTypes,
		initialRooms,
		initialTurnoverTimeThreshold,
		initialFcotsGraceMinutes,
		initialPercentile,
		initialPrimetime,
		initialAddOns,
		initialUtilizationType,
		initialAbandonedDaysToggle,
		initialTimeslotType,
		initialBlockTimeslot,
		initialBlockName,
		initialBlockNames,
		initialProcedureOptions,
		initialSVGDropDown,
		initialHeatmapMethod,
		initialSalaries,
		initialNurseStaffRatios,
		initialMDACoverRoom,
		initialTurnoverConcurrency,
		initialIndirectStaffWindow,
		initialVariableStaffWindow,
		initialPhase,
	]);

	// User filter preset is deleted from the database if they are cleared
	const clearFilters = () => {
		setSavedFilter({
			delete_filter: {
				facility_id: selectedFacility,
				page: pathname,
			},
		});
	};

	const applyFilters = async () => {
		updateDate({ applied: date.selected });
		updateDateRange({ applied: dateRange.selected });
		updateSurgeon({ applied: surgeon.selected });
		updateSurgeons({ applied: surgeons.selected });
		updateAnesthesiologists({ applied: anesthesiologists.selected });
		updateAnesthetists({ applied: anesthetists.selected });
		updateServiceLines({ applied: serviceLines.selected });
		updateEncounterTypes({ applied: encounterTypes.selected });
		updateRooms({ applied: rooms.selected });
		updateTurnoverTimeThreshold({
			selected: clamp(turnoverTimeThreshold.selected, turnoverTimeThreshold.min, turnoverTimeThreshold.max),
			applied: clamp(turnoverTimeThreshold.selected, turnoverTimeThreshold.min, turnoverTimeThreshold.max),
		});
		updateFcotsGraceMinutes({
			selected: clamp(fcotsGraceMinutes.selected, fcotsGraceMinutes.min, fcotsGraceMinutes.max),
			applied: clamp(fcotsGraceMinutes.selected, fcotsGraceMinutes.min, fcotsGraceMinutes.max),
		});
		updatePercentile({
			selected: clamp(percentile.selected, percentile.min, percentile.max),
			applied: clamp(percentile.selected, percentile.min, percentile.max),
		});
		updatePrimetime({ applied: primetime.selected });
		updateAddOns({ applied: addOns.selected });
		updateUtilizationType({ applied: utilizationType.selected });
		updateAbandonedDaysToggle({ applied: abandonedDaysToggle.selected });
		updateTimeslotType({ applied: timeslotType.selected });
		updateBlockTimeslot({ applied: blockTimeslot.selected });
		updateBlockName({ applied: blockName.selected });
		updateBlockNames({ applied: blockNames.applied });
		updateProcedures({ applied: procedures.selected });
		updateHeatmapMethod({ applied: heatmapMethod.selected });
		updateSalaries({ applied: salaries.selected });
		updateNurseStaffRatios({ applied: nurseStaffRatios.selected });
		updateMDACoverRoom({ applied: mdaCoverRoom.selected });
		updateTurnoverConcurrency({ applied: turnoverConcurrency.selected });
		updateIndirectStaffWindow({ applied: indirectStaffWindow.selected });
		updateVariableStaffWindow({ applied: variableStaffWindow.selected });
		updatePhase({ applied: phase.selected });

		// check if days of week changed
		//if the page is anesthesia and days of week is dirty, override carousel default selected
		if (!isEqual(daysOfWeek.applied, daysOfWeek.selected)) {
			dropDowns.carousel_day_of_week_selected = daysOfWeek.selected[0];
		}

		updateSVGDropDown(dropDowns);
		if (!isEqual(daysOfWeek.applied, daysOfWeek.selected)) {
			daysOfWeek.selected = daysOfWeek.selected.sort(sortDays);
			dropDowns.carousel_day_of_week_selected = daysOfWeek.selected[0];
		}
		updateDaysOfWeek({ applied: daysOfWeek.selected });
		const response = await setSavedFilter({
			set_filter: {
				page: pathname,
				notes: is_current_filter_view
					? `{ "parent_id": ${metadata?.id}, "temp_name": "${metadata?.title} (Changed)" }`
					: undefined, // indicator to check if current filter is a branch of a view
				facility_id: selectedFacility,
				surgeon: surgeon.selected,
				surgeons: !(isEqual(surgeons.selected, allSurgeons) && pathname !== '/surgeon-scorecard')
					? surgeons.selected
					: undefined,
				anesthesiologists: !isEqual(anesthesiologists.selected, allAnesthesiologists)
					? anesthesiologists.selected
					: undefined,
				anesthetists: !isEqual(anesthetists.selected, allAnesthetists) ? anesthetists.selected : undefined,
				rooms: !isEqual(rooms.selected, allRooms) ? rooms.selected : undefined,
				service_lines: !isEqual(serviceLines.selected, allServiceLines) ? serviceLines.selected : undefined,
				encounter_types: !isEqual(encounterTypes.selected, allEncounterTypes) ? encounterTypes.selected : undefined,
				add_ons: addOns.selected,
				primetime: primetime.selected,
				block_time_slot: blockTimeslot.selected,
				block_type: timeslotType.selected,
				utilization_type: utilizationType.selected,
				abandoned_days_toggle: abandonedDaysToggle.selected,
				block: blockName.selected,
				blocks: !isEqual(blockNames.selected, allBlockNames) ? blockNames.selected : undefined,
				procedures: procedures.selected.map((option) => option.name),
				date: !isEqual(date.selected, endDate) ? convertToUTC(date.selected) : undefined,
				percentile: clamp(percentile.selected, percentile.min, percentile.max),
				date_range_start: !isEqual(dateRange.selected.startDate, startDate)
					? convertToUTC(dateRange.selected.startDate)
					: undefined,
				date_range_end: !isEqual(dateRange.selected.endDate, endDate)
					? convertToUTC(dateRange.selected.endDate)
					: undefined,
				days_of_week: daysOfWeek.selected,
				turnover_upper_bound: clamp(turnoverTimeThreshold.selected, turnoverTimeThreshold.min, turnoverTimeThreshold.max),
				fcots_grace: clamp(fcotsGraceMinutes.selected, fcotsGraceMinutes.min, fcotsGraceMinutes.max),
				dropdowns: dropDowns,
				heatmap_method: heatmapMethod.selected,
				salaries: salaries.selected,
				nurse_staff_ratios: nurseStaffRatios.selected,
				mda_cover_room: mdaCoverRoom.selected,
				turnover_concurrency: turnoverConcurrency.selected,
				indirect_staff_window: indirectStaffWindow.selected,
				variable_staff_window: variableStaffWindow.selected,
				phase: phase.selected,
			},
		});
		handleApiResponse(response, {
			success: (payload) => {
				setMetadata({
					title: payload?.name ?? 'Filters',
					saved_at: payload?.updated_at,
				});
			},
			error: (payload) => {
				setMetadata({
					title: 'Filters',
					saved_at: undefined,
				});
			},
		});
	};

	const saveDropdown = async (newState: dropDowns, refreshTag?: typeof refreshTagTypes) => {
		await setSavedFilter({
			set_filter: {
				page: pathname,
				facility_id: selectedFacility,
				surgeon: surgeon.selected,
				surgeons: !isEqual(surgeons.selected, allSurgeons) ? surgeons.selected : undefined,
				anesthesiologists: !isEqual(anesthesiologists.selected, allAnesthesiologists)
					? anesthesiologists.selected
					: undefined,
				anesthetists: !isEqual(anesthetists.selected, allAnesthetists) ? anesthetists.selected : undefined,
				rooms: !isEqual(rooms.selected, allRooms) ? rooms.selected : undefined,
				service_lines: !isEqual(serviceLines.selected, allServiceLines) ? serviceLines.selected : undefined,
				encounter_types: !isEqual(encounterTypes.selected, allEncounterTypes) ? encounterTypes.selected : undefined,
				add_ons: addOns.selected,
				primetime: primetime.selected,
				block_time_slot: blockTimeslot.selected,
				block_type: timeslotType.selected,
				utilization_type: utilizationType.selected,
				abandoned_days_toggle: abandonedDaysToggle.selected,
				block: blockName.selected,
				blocks: !isEqual(blockNames.selected, allBlockNames) ? blockNames.selected : undefined,
				procedures:
					procedures.selected.map((option) => option.name).length > 0
						? procedures.selected.map((option) => option.name)
						: undefined,
				date: convertToUTC(date.selected),
				percentile: clamp(percentile.selected, percentile.min, percentile.max),
				date_range_start: convertToUTC(dateRange.selected.startDate),
				date_range_end: convertToUTC(dateRange.selected.endDate),
				days_of_week: daysOfWeek.selected,
				turnover_upper_bound: clamp(turnoverTimeThreshold.selected, turnoverTimeThreshold.min, turnoverTimeThreshold.max),
				fcots_grace: clamp(fcotsGraceMinutes.selected, fcotsGraceMinutes.min, fcotsGraceMinutes.max),
				dropdowns: newState,
				heatmap_method: heatmapMethod.selected,
				mda_cover_room: mdaCoverRoom.selected,
				salaries: salaries.selected,
				nurse_staff_ratios: nurseStaffRatios.selected,
				turnover_concurrency: turnoverConcurrency.selected,
				indirect_staff_window: indirectStaffWindow.selected,
				variable_staff_window: variableStaffWindow.selected,
				phase: phase.selected,
			},
			refreshTag: refreshTag,
		});
	};

	const saveCurrentView = async (
		name: string,
		notes: string | undefined,
		is_trailing_date_range: boolean | undefined
	) => {
		await setSavedFilter({
			set_filter: {
				name: name,
				notes: notes,
				page: pathname,
				facility_id: selectedFacility,
				surgeon: surgeon.selected,
				surgeons: !isEqual(surgeons.selected, allSurgeons) ? surgeons.selected : undefined,
				anesthesiologists: !isEqual(anesthesiologists.selected, allAnesthesiologists)
					? anesthesiologists.selected
					: undefined,
				anesthetists: !isEqual(anesthetists.selected, allAnesthetists) ? anesthetists.selected : undefined,
				rooms: !isEqual(rooms.selected, allRooms) ? rooms.selected : undefined,
				service_lines: !isEqual(serviceLines.selected, allServiceLines) ? serviceLines.selected : undefined,
				encounter_types: !isEqual(encounterTypes.selected, allEncounterTypes) ? encounterTypes.selected : undefined,
				add_ons: addOns.selected,
				primetime: primetime.selected,
				block_time_slot: blockTimeslot.selected,
				block_type: timeslotType.selected,
				utilization_type: utilizationType.selected,
				abandoned_days_toggle: abandonedDaysToggle.selected,
				block: blockName.selected,
				blocks: !isEqual(blockNames.selected, allBlockNames) ? blockNames.selected : undefined,
				date: is_trailing_date_range ? undefined : convertToUTC(date.selected),
				percentile: clamp(percentile.selected, percentile.min, percentile.max),
				date_range_start: is_trailing_date_range ? undefined : convertToUTC(dateRange.selected.startDate),
				date_range_end: is_trailing_date_range ? undefined : convertToUTC(dateRange.selected.endDate),
				days_of_week: daysOfWeek.selected,
				turnover_upper_bound: clamp(turnoverTimeThreshold.selected, turnoverTimeThreshold.min, turnoverTimeThreshold.max),
				fcots_grace: clamp(fcotsGraceMinutes.selected, fcotsGraceMinutes.min, fcotsGraceMinutes.max),
				dropdowns: dropDowns,
				salaries: salaries.selected,
				nurse_staff_ratios: nurseStaffRatios.selected,
				mda_cover_room: mdaCoverRoom.selected,
				is_trailing_date_range: is_trailing_date_range,
				heatmap_method: heatmapMethod.selected,
				turnover_concurrency: turnoverConcurrency.selected,
				indirect_staff_window: indirectStaffWindow.selected,
				variable_staff_window: variableStaffWindow.selected,
				procedures:
					procedures.selected.map((option) => option.name).length > 0
						? procedures.selected.map((option) => option.name)
						: undefined,
				phase: phase.selected,
			},
		});
	};

	const updateSavedView = async () => {
		const response = await setSavedFilter({
			update_view: true,
			set_filter: {
				page: pathname,
				facility_id: selectedFacility,
				notes: savedFilter?.notes, // need to check if branched view
				surgeon: surgeon.selected,
				surgeons: !isEqual(surgeons.selected, allSurgeons) ? surgeons.selected : undefined,
				anesthesiologists: !isEqual(anesthesiologists.selected, allAnesthesiologists)
					? anesthesiologists.selected
					: undefined,
				anesthetists: !isEqual(anesthetists.selected, allAnesthetists) ? anesthetists.selected : undefined,
				rooms: !isEqual(rooms.selected, allRooms) ? rooms.selected : undefined,
				service_lines: !isEqual(serviceLines.selected, allServiceLines) ? serviceLines.selected : undefined,
				encounter_types: !isEqual(encounterTypes.selected, allEncounterTypes) ? encounterTypes.selected : undefined,
				add_ons: addOns.selected,
				primetime: primetime.selected,
				block_time_slot: blockTimeslot.selected,
				block_type: timeslotType.selected,
				utilization_type: utilizationType.selected,
				abandoned_days_toggle: abandonedDaysToggle.selected,
				block: blockName.selected,
				blocks: !isEqual(blockNames.selected, allBlockNames) ? blockNames.selected : undefined,
				procedures: procedures.selected.map((option) => option.name),
				date: !isEqual(date.selected, endDate) ? convertToUTC(date.selected) : undefined,
				percentile: clamp(percentile.selected, percentile.min, percentile.max),
				date_range_start: !isEqual(dateRange.selected.startDate, startDate)
					? convertToUTC(dateRange.selected.startDate)
					: undefined,
				date_range_end: !isEqual(dateRange.selected.endDate, endDate)
					? convertToUTC(dateRange.selected.endDate)
					: undefined,
				days_of_week: daysOfWeek.selected,
				turnover_upper_bound: clamp(turnoverTimeThreshold.selected, turnoverTimeThreshold.min, turnoverTimeThreshold.max),
				fcots_grace: clamp(fcotsGraceMinutes.selected, fcotsGraceMinutes.min, fcotsGraceMinutes.max),
				dropdowns: dropDowns,
				heatmap_method: heatmapMethod.selected,
				salaries: salaries.selected,
				nurse_staff_ratios: nurseStaffRatios.selected,
				mda_cover_room: mdaCoverRoom.selected,
				turnover_concurrency: turnoverConcurrency.selected,
				indirect_staff_window: indirectStaffWindow.selected,
				variable_staff_window: variableStaffWindow.selected,
				phase: phase.selected,
			},
		});
		handleApiResponse(response, {
			success: (payload) => {
				setMetadata({
					title: payload?.name ?? 'Filters',
					saved_at: payload?.updated_at,
				});
			},
			error: (payload) => {
				setMetadata({
					title: 'Filters',
					saved_at: undefined,
				});
			},
		});
	};

	const applyNewView = async (
		filter_id: number,
		set_is_public?: boolean | undefined,
		set_is_archived?: boolean | undefined,
		method?: string | undefined
	) => {
		const response = await applyView({
			filter_id,
			facility_id: selectedFacility,
			page: pathname,
			set_is_public: set_is_public,
			set_is_archived: set_is_archived,
		});
		handleApiResponse(response, {
			success: (payload) => {
				if (method === 'copy') {
					toast.createToast({
						title: 'Successfully copied filter',
						variant: 'success',
					});
				}
			},
			error: (payload) => {
				toast.createToast({
					title: `${getQueryError(payload)}`,
					variant: 'error',
				});
			},
		});
	};

	// Some fields have default values that change when the active healthsystem
	// or facility is updated. On changes to these default values, reinitialize
	// the associated fields.
	useEffect(() => {
		updateDateRange(initialDateRange);
		updateSurgeon(initialSurgeon);
		updateSurgeons(initialSurgeons);
		updateAnesthesiologists(initialAnesthesiologists);
		updateAnesthetists(initialAnesthetists);
		updateServiceLines(initialServiceLines);
		updateEncounterTypes(initialEncounterTypes);
		updateRooms(initialRooms);
		updateBlockName(initialBlockName);
		updateBlockNames(initialBlockNames);
		updateSVGDropDown(initialSVGDropDown);
		updateHeatmapMethod(initialHeatmapMethod);
		updateProcedures(initialProcedureOptions);
		updateSalaries(initialSalaries);
		updateNurseStaffRatios(initialNurseStaffRatios);
		updateMDACoverRoom(initialMDACoverRoom);
		updateTurnoverConcurrency(initialTurnoverConcurrency);
		updateIndirectStaffWindow(initialIndirectStaffWindow);
		updateVariableStaffWindow(initialVariableStaffWindow);
		updatePhase(initialPhase);
	}, [
		initialDateRange,
		initialSurgeon,
		initialSurgeons,
		initialServiceLines,
		initialEncounterTypes,
		initialRooms,
		initialBlockName,
		initialBlockNames,
		initialSVGDropDown,
		initialHeatmapMethod,
		initialProcedureOptions,
		initialAnesthesiologists,
		initialAnesthetists,
		initialSalaries,
		initialNurseStaffRatios,
		initialIndirectStaffWindow,
		initialMDACoverRoom,
		initialTurnoverConcurrency,
		initialVariableStaffWindow,
		initialPhase,
	]);

	// When navigating between pages, reset the filter state.
	useEffect(() => {
		reloadFilters();
	}, [pathname, reloadFilters]);

	const filterFields = {
		date: {
			...date,
			update: updateDate,
			isDirty: !isEqual(date.applied, date.selected),
			isFetching: isFetchingSavedFilter || isFetchingDateRange,
		},

		dateRange: {
			...dateRange,
			update: updateDateRange,
			isDirty: !isEqual(dateRange.applied, dateRange.selected),
			isFetching: isFetchingSavedFilter || isFetchingDateRange,
		},

		salaries: {
			...salaries,
			update: updateSalaries,
			isDirty: !isEqual(salaries.applied, salaries.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},

		nurseStaffRatios: {
			...nurseStaffRatios,
			update: updateNurseStaffRatios,
			isDirty: !isEqual(nurseStaffRatios.applied, nurseStaffRatios.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},

		indirectStaffWindow: {
			...indirectStaffWindow,
			update: updateIndirectStaffWindow,
			isDirty: !isEqual(indirectStaffWindow.applied, indirectStaffWindow.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},

		variableStaffWindow: {
			...variableStaffWindow,
			update: updateVariableStaffWindow,
			isDirty: !isEqual(variableStaffWindow.applied, variableStaffWindow.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},

		mdaCoverRoom: {
			...mdaCoverRoom,
			update: updateMDACoverRoom,
			isDirty: !isEqual(mdaCoverRoom.applied, mdaCoverRoom.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},

		turnoverConcurrency: {
			...turnoverConcurrency,
			update: updateTurnoverConcurrency,
			isDirty: !isEqual(turnoverConcurrency.applied, turnoverConcurrency.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},

		surgeon: {
			...surgeon,
			update: updateSurgeon,
			isDirty: !isEqual(surgeon.applied, surgeon.selected),
			isFetching: isFetchingSavedFilter || isFetchingSurgeons,
		},

		surgeons: {
			...surgeons,
			update: updateSurgeons,
			isDirty: !isEqual(surgeons.applied, surgeons.selected),
			isFetching: isFetchingSavedFilter || isFetchingSurgeons,
		},

		anesthesiologists: {
			...anesthesiologists,
			update: updateAnesthesiologists,
			isDirty: !isEqual(anesthesiologists.applied, anesthesiologists.selected),
			isFetching: isFetchingSavedFilter || isFetchingAnesthesiologists,
		},

		anesthetists: {
			...anesthetists,
			update: updateAnesthetists,
			isDirty: !isEqual(anesthetists.applied, anesthetists.selected),
			isFetching: isFetchingSavedFilter || isFetchingAnesthetists,
		},

		daysOfWeek: {
			...daysOfWeek,
			update: updateDaysOfWeek,
			isDirty: !isEqual(daysOfWeek.applied, daysOfWeek.selected),
			isFetching: isFetchingSavedFilter || isFetchingEncounterTypes,
		},

		serviceLines: {
			...serviceLines,
			update: updateServiceLines,
			isDirty: !isEqual(serviceLines.applied, serviceLines.selected),
			isFetching: isFetchingSavedFilter || isFetchingServiceLines,
		},

		encounterTypes: {
			...encounterTypes,
			update: updateEncounterTypes,
			isDirty: !isEqual(encounterTypes.applied, encounterTypes.selected),
			isFetching: isFetchingSavedFilter || isFetchingEncounterTypes,
		},

		rooms: {
			...rooms,
			update: updateRooms,
			isDirty: !isEqual(rooms.applied, rooms.selected),
			isFetching: isFetchingSavedFilter || isFetchingRooms,
		},

		turnoverTimeThreshold: {
			...turnoverTimeThreshold,
			update: updateTurnoverTimeThreshold,
			isDirty: !isEqual(turnoverTimeThreshold.applied, turnoverTimeThreshold.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},

		fcotsGraceMinutes: {
			...fcotsGraceMinutes,
			update: updateFcotsGraceMinutes,
			isDirty: !isEqual(fcotsGraceMinutes.applied, fcotsGraceMinutes.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},

		percentile: {
			...percentile,
			update: updatePercentile,
			isDirty: !isEqual(percentile.applied, percentile.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},

		primetime: {
			...primetime,
			update: updatePrimetime,
			isDirty: !isEqual(primetime.applied, primetime.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},

		addOns: {
			...addOns,
			update: updateAddOns,
			isDirty: !isEqual(addOns.applied, addOns.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},

		utilizationType: {
			...utilizationType,
			update: updateUtilizationType,
			isDirty: !isEqual(utilizationType.applied, utilizationType.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},

		timeslotType: {
			...timeslotType,
			update: updateTimeslotType,
			isDirty: !isEqual(timeslotType.applied, timeslotType.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},

		blockTimeslot: {
			...blockTimeslot,
			update: updateBlockTimeslot,
			isDirty: !isEqual(blockTimeslot.applied, blockTimeslot.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},
		abandonedDaysToggle: {
			...abandonedDaysToggle,
			update: updateAbandonedDaysToggle,
			isDirty: !isEqual(abandonedDaysToggle.applied, abandonedDaysToggle.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},
		blockName: {
			...blockName,
			update: updateBlockName,
			isDirty: !isEqual(blockName.applied, blockName.selected),
			isFetching: isFetchingSavedFilter || isFetchingBlockNames,
		},
		blockNames: {
			...blockNames,
			update: updateBlockNames,
			isDirty: !isEqual(blockNames.applied, blockNames.selected),
			isFetching: isFetchingSavedFilter || isFetchingBlockNames,
		},
		procedures: {
			...procedures,
			update: updateProcedures,
			isDirty: !isEqual(procedures.applied, procedures.selected),
			isFetching: isFetchingSavedFilter || isFetchingProcedureOptions,
		},
		heatmapMethod: {
			...heatmapMethod,
			update: updateHeatmapMethod,
			isDirty: !isEqual(heatmapMethod.applied, heatmapMethod.selected),
			isFetching: isFetchingSavedFilter,
		},
		dropDowns: {
			...dropDowns,
			update: updateSVGDropDown,
			isDirty: !isEqual(initialSVGDropDown, dropDowns),
			isFetching: isFetchingSavedFilter ?? false,
		},
		phase: {
			...phase,
			update: updatePhase,
			isDirty: !isEqual(phase.applied, phase.selected),
			isFetching: isFetchingSavedFilter ?? false,
		},
	};

	const filterContextValue = {
		...filterFields,
		saveDropdown,
		resetFilters,
		saveCurrentView,
		updateSavedView,
		setFilterStatus,
		applyNewView,
		applyFilters,
		clearFilters,
		currentPageLoaded,
		reloadFilters,
		metadata,
		endDate,
		startDate,
		earliest_date,
		filtersAreDirty: Object.values(filterFields).some((field) => field.isDirty),
		filtersAreFetching: Object.values(filterFields).some((field) => field.isFetching),
	};

	return <FilterContext.Provider value={filterContextValue}>{children}</FilterContext.Provider>;
}

// Default our context hook to an "authenticated" state since that will likely
// be the most common use case.
export const useFilters = (page?: string) => useContext(FilterContext);

export default FilterProvider;
