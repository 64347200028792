import { ApiEndpoints, FacilityLicense } from 'models';
import { standardApi } from 'store/api';
import { StaffingGridItem } from 'pages/Anesthesia/components';

import { ShortOption } from './FilterService';
import { AnesthesiaCoverageResponse, NurseCoverageResponse } from './CoreService';

import type { Facility, FieldMapping } from 'models/models';

const apiWithTags = standardApi.enhanceEndpoints({
	addTagTypes: ['Anesthesia', 'Filters'],
});

// TODO: This is a dummy interface
interface AnesthesiaDashboardResponse {
	current_month: string;
	overview_date_start: Date;
	overview_date_end: Date;
}

interface ToggleState {
	medical_ratio: number;
	eight_hour_shift: boolean;
	ten_hour_shift: boolean;
	twelve_hour_shift: boolean;
	fourteen_hour_shift: boolean;
}

export interface BoxWhiskerItem {
	x: string;
	minutes_worked: number;
	cases: number;
	shortest: number;
	longest: number;
	omitted_cases: number;
	days: number;
	q1: number;
	q3: number;
	average: number;
	avg_shift_time: number;
	median: number;
	max: number;
	min: number;
	end_box: {
		x: string;
		minutes_worked: number;
		cases: number;
		shortest: number;
		longest: number;
		omitted_cases: number;
		days: number;
		q1: number;
		q3: number;
		average: number;
		avg_shift_time: number;
		median: number;
		max: number;
		min: number;
	};
}

export interface BoxWhiskerItemPlain {
	x: string;
	minutes_worked: number;
	cases: number;
	days: number;
	shortest: number;
	longest: number;
	omitted_cases: number;
	avg_shift_time: number;
	q1: number;
	q3: number;
	average: number;
	median: number;
	max: number;
	min: number;
}

export interface ProviderPatternsResponse {
	anesthesiologists: BoxWhiskerItem[];
	anesthetists: BoxWhiskerItem[];
}

interface ProviderPatternRequest {
	healthsystem_id: number | null;
	filters: {
		days_of_week: string[];
		anesthesiologists: ShortOption[];
		anesthetists: ShortOption[];
		start_date: string;
		end_date: string;
	};
}

export interface StaffingCompareItem {
	cost: number;
	mda_fte: number;
	crna_fte: number;
	model_parameters: string;
}

export interface NurseStaffingCompareItem {
	cost: number;
	mda_fte: number;
	crna_fte: number;
	model_parameters: string;
}

export interface StaffingCompareResponse {
	data: StaffingCompareItem[];
}

/**
 * Provides endpoints for setting values in database
 */
export const anesthesiaService = apiWithTags.injectEndpoints({
	endpoints: (build) => ({
		getAnesthesiaDashboard: build.query<AnesthesiaDashboardResponse, { facility_id: number | null }>({
			query: ({ facility_id }) => ({
				url: ApiEndpoints.GET_ANESTHESIA_DASHBOARD,
				params: {
					facility_id: facility_id !== null ? facility_id : undefined,
				},
			}),
			providesTags: ['Anesthesia'],
		}),
		getAnesthesiaCoverage: build.query<
			AnesthesiaCoverageResponse,
			{
				facility_id?: number | null;
				filter_id?: number;
				filters?: {
					days_of_week: string[];
					rooms: ShortOption[];
					service_lines: ShortOption[];
					encounter_types: ShortOption[];
					surgeons: ShortOption[];
					add_ons: string;
					start_date: string;
					end_date: string;
					heatmap_method: string;
					percentile: number;
					mda_cover_room: boolean;
					turnover_concurrency: 'Included' | 'Excluded' | 'Only Turnover';
					turnover_threshold: number;
				};
				toggleStates?: ToggleState;
				staffingGrid?: StaffingGridItem[];
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_ANESTHESIA_COVERAGE,
				method: 'POST',
				body,
			}),
			providesTags: ['Anesthesia'],
		}),
		getStaffingCompare: build.query<
			StaffingCompareResponse,
			{
				facility_id: number | null;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_STAFFING_COMPARE,
				method: 'POST',
				body,
			}),
			providesTags: ['Anesthesia'],
		}),
		getProviderPatterns: build.query<ProviderPatternsResponse, ProviderPatternRequest>({
			query: (body) => ({
				url: ApiEndpoints.GET_PROVIDER_PATTERNS,
				method: 'POST',
				body,
			}),
		}),
		getNurseCoverage: build.query<
			NurseCoverageResponse,
			{
				facility_id?: number | null;
				filter_id?: number;
				filters?: {
					days_of_week?: string[];
					rooms?: ShortOption[];
					service_lines?: ShortOption[];
					encounter_types?: ShortOption[];
					surgeons?: ShortOption[];
					add_ons?: string;
					start_date?: string;
					end_date?: string;
					heatmap_method?: string;
					percentile?: number;
					turnover_concurrency?: 'Included' | 'Excluded' | 'Only Turnover';
					turnover_threshold?: number;
					variable_staff_ratio?: number;
					indirect_staff_ratio?: number;
					indirect_staff_window_start?: string;
					indirect_staff_window_end?: string;
					variable_staff_window_start?: string;
					variable_staff_window_end?: string;
					phase?: string;
				};
				toggleStates?: ToggleState;
				staffingGrid?: StaffingGridItem[];
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_NURSE_COVERAGE,
				method: 'POST',
				body,
			}),
			providesTags: ['Anesthesia'],
		}),
	}),
});

export const {
	useGetAnesthesiaDashboardQuery,
	useGetAnesthesiaCoverageQuery,
	useGetStaffingCompareQuery,
	useGetNurseCoverageQuery,
	useGetProviderPatternsQuery,
} = anesthesiaService;

export const {
	endpoints: {
		getAnesthesiaDashboard,
		getAnesthesiaCoverage,
		getStaffingCompare,
		getNurseCoverage,
		getProviderPatterns,
	},
} = anesthesiaService;
