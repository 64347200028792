import { UserInfo, UserRole } from 'models';
import { OktaErrorDescriptions } from 'models/authentication';

/**
 * Knowing that okta information is kept in local storage, clear that and refresh on the homepage.
 */
export function clearSessionAndLogout() {
	localStorage.removeItem('okta-cache-storage');
	localStorage.removeItem('okta-token-storage');
	window.location.href = '/';
}

export function clearSessionCache() {
	localStorage.removeItem('okta-cache-storage');
	localStorage.removeItem('okta-token-storage');
}

/**
 * Simple utility function to check the role of a user against a single required role or a list of allowed roles.
 * @param role the role/roles to be required for the function to return true
 * @param user the user being checked
 * @returns boolean, if the user's role matches the required roles specified by the developer
 */
export function userIs(role: UserRole | UserRole[], user: UserInfo | undefined): boolean {
	if (!user) return false;
	if (Array.isArray(role)) {
		return role.includes(user.role);
	} else {
		return user.role === role;
	}
}

/**
 * Determines the proper error description we'd like to show to end users when encountering certain errors while interacting with Okta/their session.
 * @param err the error code from Okta
 * @returns string, description we'd like to show when encountering certain Okta error codes
 */
export function getOktaErrorMessage(err?: string) {
	if (err && err in OktaErrorDescriptions) {
		return OktaErrorDescriptions[err];
	}

	return OktaErrorDescriptions.generic;
}

/**
 * Determines if sequences of strings n letters long from the email field are also in the pasword value
 * @param value the password field value
 * @param email the current value in the email-field input
 * @param chunkLength typically 3,4 - determines how long the "chunks" are which are checked in the password
 * @returns boolean, if any chunks of the email are in the password value
 */
export function doesPasswordContainPartOfEmail(value: string, email: string | undefined, chunkLength: number) {
	// split email into chunks of 3 characters
	if (!email || !value) return false;
	const length = email.length;
	const chunks = [];
	for (let i = 0; i < length; i++) {
		if (i + chunkLength <= length) {
			chunks.push(email.substring(i, i + chunkLength));
		}
	}
	let noChunksFound = true;
	chunks.forEach((chunk) => {
		if (value.includes(chunk)) {
			noChunksFound = false;
		}
	});

	return noChunksFound;
}
