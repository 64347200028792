import * as yup from 'yup';
import { FieldErrors, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { Dialog, MultiSelect, Select, TextField } from 'components';
import { getFacilityName, getRoleDesignationText, inputFieldRegex } from 'utils';
import { getQueryError, handleApiResponse, useAddUserMutation, useSystem, useUpdateUserMutation } from 'store';
import { useAlert, useToast } from 'context';
import { UserRole } from 'models';

import type { Facility, UserInfo } from 'models';
import type { AddUserApiRequest } from 'store';
import type { SubmitHandler } from 'react-hook-form';

export interface UserFormValues {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
	role: UserRole;
	defaultFacility: number;
	access: string;
}

/**
 * Add/Edit User Form Structure
 */
export const UserFormSchema = yup.object({
	id: yup.number(),
	firstName: yup
		.string()
		.required('A first name is required')
		.max(100, 'First name cannot be more than 100 characters')
		.matches(inputFieldRegex, 'First Name is not valid.'),
	lastName: yup
		.string()
		.required('A last name is required')
		.max(100, 'Last name cannot be more than 100 characters')
		.matches(inputFieldRegex, 'Last Name is not valid.'),
	email: yup
		.string()
		.email('Please enter a valid email address')
		.required('An email address is required')
		.max(100, 'Email cannot be more than 100 characters'),
	role: yup.number().min(1, 'A role is required').max(6).required('A role is required'),
	defaultFacility: yup.number().required('A default facility is required').min(1, 'A default facility is required'),
	access: yup.array().of(yup.number()).min(1, 'A facility is required'),
	hidden_healthsystems: yup.array().of(yup.number()).min(0),
});

export type UserFormInputs = yup.InferType<typeof UserFormSchema>;

export interface UserSettingsDialogProps {
	children: React.ReactNode;
	user?: UserInfo;
	facilities: Facility[];
}

const roleOptions = [
	{
		label: `${getRoleDesignationText(UserRole.admin)}`,
		value: UserRole.admin,
	},
	{
		label: `${getRoleDesignationText(UserRole.healthsystem_admin)}`,
		value: UserRole.healthsystem_admin,
	},
	{
		label: `${getRoleDesignationText(UserRole.facility_basic)}`,
		value: UserRole.facility_basic,
	},
	/* TODO: add the other role options here one day, when backend can accomodate it */
];

export function UserSettingsDialog({ children, facilities, user }: UserSettingsDialogProps) {
	const toast = useToast();
	const [shouldResetForm, setShouldResetForm] = useState(false);
	const [addUser, { isLoading: isAdding, error, isError }] = useAddUserMutation();
	const [updateUser, { isLoading: isUpdating, error: updateError, isError: isUpdateError }] = useUpdateUserMutation();
	const currentUserFacilityIds = user ? user.facilities.map((f) => f.id) : [];
	const facilityOptions = facilities.map((f) => ({
		label: getFacilityName(f),
		value: f.id,
	}));

	// keep state for the selected facilities in the access dropdown
	const [selectedFacilities, setSelectedFacilities] = useState<{ label: string; value: number }[]>(
		user ? facilityOptions.filter((f) => currentUserFacilityIds.includes(f.value)) : []
	);

	// keep state for the default facility dropdown
	const currentUserDefaultFacility = facilityOptions.find((opt) => opt.value === user?.default_facility);
	const [selectedDefault, setSelectedDefaultFacility] = useState<{ label: string; value: number } | null>(
		currentUserDefaultFacility ?? null
	);

	// keep state for role dropdown
	const currentUserRole = roleOptions.find((opt) => opt.value === user?.role);
	const [selectedRole, setSelectedRole] = useState<{ label: string; value: number } | null>(currentUserRole ?? null);

	//Applicable user roleOptions
	const { data: userData } = useSystem();
	const applicableRoleOptions = userData ? roleOptions.filter((val) => val.value >= userData?.user?.role) : [];
	const healthsystems = userData?.healthsystems.map((h) => ({ value: h.id, label: h.name })) ?? [];

	// set up all of the form logic/handlers
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<UserFormInputs>({
		resolver: yupResolver(UserFormSchema),
		mode: 'onChange',
		defaultValues: {
			id: user?.id,
			firstName: user?.first_name ?? '',
			lastName: user?.last_name ?? '',
			email: user?.email ?? '',
			role: user?.role ?? 0,
			defaultFacility: user?.default_facility, // no facility will have this id
			access: user?.facilities.map((f) => f.id) ?? [],

			// use the id to find the healthsystem from healthsystems array
			hidden_healthsystems: user?.hidden_healthsystems ?? [],
		},
	});
	const { getAlertResponse } = useAlert();
	const [dialogOpen, setDialogOpen] = useState(false);

	const onSubmit: SubmitHandler<UserFormInputs> = async (formData) => {
		// first, build the request by appending the id if editing an existing user
		const facilityIds = formData.access ? formData.access.map((v) => v) : [];
		const hidden_healthsystems = formData.hidden_healthsystems?.flatMap((v) => (v !== undefined ? [v] : []));
		const requestBody: AddUserApiRequest = {
			first_name: formData.firstName,
			last_name: formData.lastName,
			email: formData.email,
			role: formData.role,
			default_facility: formData.defaultFacility,
			facility_access: facilityIds,
			hidden_healthsystems: hidden_healthsystems ?? [],
		};

		// bundle the calls into an if-else
		let response;
		if (formData.id) {
			response = await updateUser({
				id: formData.id,
				...requestBody,
			});
		} else {
			response = await addUser(requestBody);
		}

		// handle either type of response (edit / add)
		handleApiResponse(response, {
			success: (payload) => {
				if (!user) setShouldResetForm(true);
				toast.createToast({
					title: user
						? `${payload.user.first_name} ${payload.user.last_name} has been successfully updated.`
						: `${payload.user.first_name} ${payload.user.last_name} has been successfully created.`,
					variant: 'success',
				});
				setDialogOpen(false);
			},
			error: (payload) => {
				toast.createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	// on every re-render, recreate the function that resets the form
	const resetForm = useCallback(() => {
		setValue('role', 0);
		setValue('defaultFacility', 0);
		setValue('access', []);
		setSelectedFacilities([]);
		setSelectedDefaultFacility(null);
		setSelectedRole(null);
		reset();
	}, [reset, setSelectedDefaultFacility, setSelectedFacilities, setSelectedRole, setValue]);

	useEffect(() => {
		// if at any point our component just submitted a new user, on the next render lets reset our form
		if (shouldResetForm) {
			resetForm();
			setShouldResetForm(false);
		}
	}, [resetForm, setShouldResetForm, shouldResetForm]);

	return (
		<Dialog
			actionButtons={[
				{
					children: `${user ? 'Update' : 'Add New'} User`,
					isWorking: isAdding || isUpdating,
					onClick: handleSubmit(onSubmit),
				},
			]}
			sizeX='lg'
			title={`${user ? 'Edit' : 'Add New'} User`}
			trigger={children}
			open={dialogOpen}
			onOpenChange={async (isBeingOpened) => {
				if (isBeingOpened) {
					setDialogOpen(true);
					return;
				} else if (user) {
					setDialogOpen(false);
					return;
				}

				const alertResponse = await getAlertResponse({
					description:
						"Closing the window will clear any of the information you've entered in the form, are you sure you'd like to continue?",
					responseOptions: [
						{
							value: 'yes',
							label: 'Yes, close',
						},
					],
				});

				if (alertResponse === 'yes') {
					resetForm();
					setDialogOpen(false);
				}
			}}
		>
			<form className='flex flex-col overflow-auto' onSubmit={handleSubmit(onSubmit)} noValidate autoComplete='off'>
				<div className='grid grid-cols-2 gap-10 py-4 px-8'>
					<div>
						<TextField
							disabled={!!user}
							label='First Name'
							type='text'
							sizeY='md'
							errorMessage={errors.firstName?.message}
							{...register('firstName')}
						/>
					</div>

					<div>
						<TextField
							disabled={!!user}
							label='Last Name'
							type='text'
							sizeY='md'
							errorMessage={errors.lastName?.message}
							{...register('lastName')}
						/>
					</div>

					<div>
						<TextField
							disabled={!!user}
							label='Email'
							type='email'
							sizeY='md'
							errorMessage={errors.email?.message}
							{...register('email')}
						/>
					</div>

					<div>
						<Select
							label='Access Level (Role)'
							sizeY='md'
							errorMessage={errors.role?.message}
							options={applicableRoleOptions}
							value={selectedRole}
							onChange={(option) => {
								if (option) {
									setValue('role', option.value, { shouldValidate: true });
									setSelectedRole(option);
								}
							}}
						/>
					</div>

					<div>
						<MultiSelect
							label='Facilities'
							sizeY='md'
							errorMessage={(errors.access as FieldErrors)?.message}
							options={facilityOptions}
							value={selectedFacilities}
							onChange={(selected) => {
								// since we're updating the facilities the user has access to, reset the default facility dropdown field
								setSelectedFacilities(
									selected.map((option) => ({
										label: option.label,
										value: option.value,
									}))
								);
								setSelectedDefaultFacility(null);

								// make the same updates to our react-hook state above to the underlying form state as well
								setValue('defaultFacility', 0, { shouldValidate: true });
								setValue(
									'access',
									selected.map((option) => option.value),
									{ shouldValidate: true }
								);
							}}
						/>
					</div>

					<div>
						<Select
							label='Default Facility'
							sizeY='md'
							errorMessage={errors.defaultFacility?.message}
							options={selectedFacilities}
							value={selectedDefault}
							onChange={(option) => {
								if (option) {
									// update the react-hook state and the react-hook-form state for this field
									setSelectedDefaultFacility(option);
									setValue('defaultFacility', option.value, { shouldValidate: true });
								}
							}}
						/>
					</div>
					<div>
						{getValues('role') === UserRole.admin && (
							<MultiSelect
								label='Hidden Health Systems'
								sizeY='md'
								errorMessage={errors.defaultFacility?.message}
								options={healthsystems}
								value={healthsystems
									.filter((h) => getValues('hidden_healthsystems')?.includes(h.value))
									.map((option) => ({
										label: option.label,
										value: option.value,
									}))}
								onChange={(option) => {
									if (option) {
										// update the react-hook state and the react-hook-form state for this field
										// setSelectedDefaultFacility(option);
										setValue(
											'hidden_healthsystems',
											option.map((o) => o.value),

											{ shouldValidate: true }
										);
									}
								}}
							/>
						)}
					</div>
					<div className='flex flex-col justify-around text-center align-middle mt-2'>
						{(user?.hidden_healthsystems ?? []).length > 0 && userData?.user.id === user?.id && (
							<div className='text-yellow-500 text-p2'>{`${
								(user?.hidden_healthsystems ?? []).length
							} health system(s) are hidden for you.`}</div>
						)}
					</div>
				</div>
				{isUpdateError && updateError && <p className='my-2 text-p2 text-red-400'>{getQueryError(updateError)}</p>}
				{isError && error && <p className='my-2 text-p2 text-red-400'>{getQueryError(error)}</p>}
			</form>
		</Dialog>
	);
}

export default UserSettingsDialog;
