import { UserInfo, UserRole } from 'models/models';
import { ApiEndpoints } from 'models/api';
import { standardApi } from 'store/api';
import { ResetPassword } from 'pages';

export interface GetUsersApiResponse {
	users: UserInfo[];
}

export interface AddUserApiRequest {
	first_name: string;
	last_name: string;
	email: string;
	role: UserRole;
	default_facility: number;
	facility_access: (number | undefined)[];
	hidden_healthsystems: number[];
}

export interface RegisterUserApiRequest {
	first_name: string;
	last_name: string;
	email: string;
	password: string;
	token: string;
}

export interface UpdateUserApiRequest {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
	role: UserRole;
	default_facility: number;
	facility_access: (number | undefined)[];
	hidden_healthsystems: number[];
}

export interface UserApiResponse {
	user: UserInfo;
}

export interface RegisterUserApiResponse {
	success: boolean;
	message: string;
}

export interface DeleteUserApiResponse {
	message: string;
}

const apiWithTags = standardApi.enhanceEndpoints({ addTagTypes: ['Users'] });

/**
 * Provides endpoints for retrieving initial state (healthsystems, facilities) and creating new healthsystems
 */
export const userSettingsApi = apiWithTags.injectEndpoints({
	endpoints: (build) => ({
		getUsers: build.query<
			GetUsersApiResponse,
			{ healthsystem_id?: number | null; short?: boolean; short_with_local?: boolean }
		>({
			query: ({ healthsystem_id, short, short_with_local }) => ({
				url: ApiEndpoints.USERS,
				params: {
					healthsystem_id: healthsystem_id !== null ? healthsystem_id : undefined,
					short, // If `short` is true, it returns all emails and ids for users in the health system.
					short_with_local, // If `short_with_local` is true, it also returns all admin users with hs users.
				},
			}),
			providesTags: ['Users'],
		}),
		addUser: build.mutation<UserApiResponse, AddUserApiRequest>({
			query: (body) => ({
				url: ApiEndpoints.ADD_USER,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Users'],
		}),
		updateUser: build.mutation<UserApiResponse, UpdateUserApiRequest>({
			query: (body) => ({
				url: ApiEndpoints.UPDATE_USER,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Users'],
		}),
		deleteUser: build.mutation<DeleteUserApiResponse, Partial<UpdateUserApiRequest>>({
			query: (body) => ({
				url: ApiEndpoints.UPDATE_USER,
				method: 'DELETE',
				body,
			}),
			invalidatesTags: ['Users'],
		}),
		registerUser: build.mutation<RegisterUserApiResponse, RegisterUserApiRequest>({
			query: (body) => ({
				url: `${ApiEndpoints.REGISTER_USER}?token=${body.token}`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Users'],
		}),
		requestPasswordReset: build.mutation<RegisterUserApiResponse, { email: string }>({
			query: (body) => ({
				url: ApiEndpoints.REQUEST_PASSWORD_RESET,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Users'],
		}),
		requestPasswordResetAdmin: build.mutation<RegisterUserApiResponse, { email: string }>({
			query: (body) => ({
				url: ApiEndpoints.REQUEST_PASSWORD_RESET_ADMIN,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Users'],
		}),
		resetPassword: build.mutation<RegisterUserApiResponse, { password: string; token: string }>({
			query: (body) => ({
				url: `${ApiEndpoints.RESET_PASSWORD}?token=${body.token}`,
				method: 'POST',
				body,
			}),

			invalidatesTags: ['Users'],
		}),
	}),
});

export const {
	useGetUsersQuery,
	useAddUserMutation,
	useDeleteUserMutation,
	useUpdateUserMutation,
	useRegisterUserMutation,
	useResetPasswordMutation,
	useRequestPasswordResetMutation,
	useRequestPasswordResetAdminMutation,
} = userSettingsApi;

export const {
	endpoints: {
		addUser,
		deleteUser,
		getUsers,
		updateUser,
		registerUser,
		requestPasswordReset,
		requestPasswordResetAdmin,
		resetPassword,
	},
} = userSettingsApi;
