import { format } from 'date-fns';
import { forwardRef, useRef, useState } from 'react';
import classNames from 'classnames';
import * as RadixSlider from '@radix-ui/react-slider';

import { Datepicker, MultiSelect, NumberField, RadioButton, Select, TextField, Timepicker, Tooltip } from 'components';
import { useAuth, useFilters } from 'context';
import {
	NurseStaffRatio,
	Salary,
	ShortOption,
	StaffWindow,
	useAppSelector,
	useGetDefaultStartEndDateQuery,
	useSystem,
} from 'store';
import { getFacilityName, stringTimeToDate } from 'utils';

import type { FilterContextField, FilterFieldName, NurseStaffRatioState, SalaryState } from 'context';

export interface FilterFieldsProps {
	/** The filter fields to display. */
	fields: FilterFieldName[];
	dateRangeLimit?: number;
	extendEndDate?: boolean;
}

interface RadialOptions {
	key: string;
	value: string;
	checked: boolean;
}

function FilterFields({ fields, dateRangeLimit = 3, extendEndDate = false }: FilterFieldsProps) {
	const {
		date,
		dateRange,
		surgeon,
		surgeons,
		anesthesiologists,
		anesthetists,
		daysOfWeek,
		serviceLines,
		encounterTypes,
		rooms,
		turnoverTimeThreshold,
		fcotsGraceMinutes,
		percentile,
		primetime,
		addOns,
		utilizationType,
		timeslotType,
		blockTimeslot,
		endDate,
		earliest_date,
		blockName,
		blockNames,
		procedures,
		heatmapMethod,
		salaries,
		mdaCoverRoom,
		nurseStaffRatios,
		turnoverConcurrency,
		indirectStaffWindow,
		variableStaffWindow,
		abandonedDaysToggle,
		phase,
	} = useFilters();

	const { selectedFacility } = useAppSelector((state) => state.userState);

	const { data: defaultDateRange } = useGetDefaultStartEndDateQuery({
		facility_id: selectedFacility,
	});

	const endDateExtensionDate =
		extendEndDate === true && defaultDateRange?.schedule_end_date
			? new Date(defaultDateRange?.schedule_end_date)
			: endDate;

	const ref = useRef<null | HTMLDivElement>(null);
	const { data: authUserData } = useSystem();
	const role = authUserData?.user.role;
	const costAccessEnabled = role === 1 || role === 4;

	const handleClick = () => ref.current?.scrollIntoView({ behavior: 'smooth' });

	// This code handles radial button creation and state for each procedure toggle.
	const proceduresSelected: RadialOptions[] = [];
	const procedure_filtering_prefix = ['', '+', '-'];
	const procedure_string_prefix = ['All', '', 'Non-'];
	procedures.all.forEach((p) => {
		let procedure_name = p.name;
		let is_included = false;
		let is_excluded = false; // will be used in the future to handle procedure filtering

		if (procedure_name.charAt(0) === '+') {
			is_included = true;
			procedure_name = procedure_name.substring(1);
		} else if (procedure_name.charAt(0) === '-') {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			is_excluded = true;
			procedure_name = procedure_name.substring(1);
		}

		for (let i = 0; i < 3; i++) {
			proceduresSelected.push({
				key: `${procedure_string_prefix[i]}${
					i !== 0 ? procedure_name.charAt(0).toUpperCase() + procedure_name.substring(1) : ''
				}`,
				value: `${procedure_filtering_prefix[i]}${procedure_name}`,
				checked:
					is_included ||
					(procedures.selected.filter((p) => p.name === `${procedure_filtering_prefix[i]}${procedure_name}`).length > 0 &&
						!is_included &&
						!is_excluded),
			});
		}

		const selected = proceduresSelected.filter((p) => p.checked);
		if (selected.length === 0) {
			proceduresSelected[0].checked = true;
		}
	});

	return (
		<div className='p-4 flex flex-col gap-4'>
			{fields.includes('date') && (
				<div>
					<div className='mb-2 font-preset-label'>Date{date.isDirty ? ' (Changed)' : ''}</div>
					<Datepicker
						type='date'
						popoverContentProps={{ side: 'left' }}
						onApply={(newDate) => date.update({ selected: newDate })}
						selected={date.selected}
					>
						<div className='flex border rounded-sm border-gray-400 p-2 cursor-pointer text-p3'>
							<div className='rounded text-white bg-blue-500 py-1 px-2'>{format(date.selected, 'M/d/yyyy')}</div>
						</div>
					</Datepicker>
				</div>
			)}
			{fields.includes('dateRange') && (
				<div>
					<div className='mb-2 font-preset-label'>Date Range{dateRange.isDirty ? ' (Changed)' : ''}</div>
					<Datepicker
						type='range'
						shortcuts
						popoverContentProps={{ side: 'left' }}
						onApplyRange={(startDate, endDate) => {
							dateRange.update({ selected: { startDate, endDate } });
						}}
						selectedRange={[dateRange.selected.startDate, dateRange.selected.endDate]}
						defaultEndDate={new Date(endDateExtensionDate)}
						defaultStartDate={earliest_date}
						dateRangeLimit={dateRangeLimit}
					>
						<div className='border rounded-sm border-gray-400 p-2 cursor-pointer text-p3'>
							<div className='flex items-center gap-1'>
								<div className='flex-1 text-center rounded text-white bg-blue-500 py-1 px-2'>
									{format(dateRange.selected.startDate, 'M/d/yyyy')}
								</div>
								&ndash;
								<div className='flex-1 text-center rounded text-white bg-blue-500 py-1 px-2'>
									{format(dateRange.selected.endDate, 'M/d/yyyy')}
								</div>
								<div className='shrink-0 right-6'>
									{dateRangeLimit && (
										<Tooltip
											content={`Please select a date range up to ${dateRangeLimit} ${dateRangeLimit > 1 ? 'years' : 'year'}`}
										>
											<span className='material-symbol text-blue-500'>info</span>
										</Tooltip>
									)}
								</div>
							</div>
						</div>
					</Datepicker>
				</div>
			)}
			{/* // yes md only // 600,000 // 250,000 */}
			{fields.includes('salaries') && costAccessEnabled && (
				<div className='w-full'>
					<div className='mb-2 font-preset-label'>Annual Salaries{salaries.isDirty ? ' (Changed)' : ''}</div>
					{fields.includes('nurseStaffRatios') ? (
						<div className='border rounded-sm border-gray-400 p-2'>
							<div className='flex justify-between items-baseline '>
								<p className='text-[0.7em] bg-blue-500 rounded-sm text-white px-1.5 py-0.5 pb-1'>Registered Nurse</p>
								<div className='flex text-[0.8em] items-baseline mb-2'>
									<p className='mr-1 font-preset-label'>$</p>
									<SalaryInput name={'registered_nurse'} filterState={salaries} />
								</div>
							</div>

							<div className='flex justify-between items-baseline'>
								<p className='text-[0.7em] bg-blue-500 rounded-sm text-white px-1.5 py-0.5 pb-1'>Surgical Tech</p>
								<div className='flex text-[0.8em] items-baseline mb-1'>
									<p className='mr-1 font-preset-label'>$</p>
									<SalaryInput name={'surgical_tech'} filterState={salaries} />
								</div>
							</div>
							<div className='flex justify-between items-baseline'>
								<p className='text-[0.7em] bg-blue-500 rounded-sm text-white px-1.5 py-0.5 pb-1'>Variable Staff</p>
								<div className='flex text-[0.8em] items-baseline mb-1'>
									<p className='mr-1 font-preset-label'>$</p>
									<SalaryInput name={'variable_staff'} filterState={salaries} />
								</div>
							</div>
							<div className='flex justify-between items-baseline'>
								<p className='text-[0.7em] bg-blue-500 rounded-sm text-white px-1.5 py-0.5 pb-1'>Indirect Staff</p>
								<div className='flex text-[0.8em] items-baseline mb-1'>
									<p className='mr-1 font-preset-label'>$</p>
									<SalaryInput name={'indirect_staff'} filterState={salaries} />
								</div>
							</div>
						</div>
					) : (
						<div className='border rounded-sm border-gray-400 p-2'>
							<div className='flex justify-between items-baseline '>
								<p className='text-[0.7em] bg-blue-500 rounded-sm text-white px-1.5 py-0.5 pb-1'>Anesthesiologist</p>
								<div className='flex text-[0.8em] items-baseline mb-2'>
									<p className='mr-1 font-preset-label'>$</p>
									<SalaryInput name={'anesthesiologist'} filterState={salaries} />
								</div>
							</div>

							<div className='flex justify-between items-baseline'>
								<p className='text-[0.7em] bg-blue-500 rounded-sm text-white px-1.5 py-0.5 pb-1'>Anesthetist</p>
								<div className='flex text-[0.8em] items-baseline mb-1'>
									<p className='mr-1 font-preset-label'>$</p>
									<SalaryInput name={'anesthetist'} filterState={salaries} />
								</div>
							</div>
						</div>
					)}
				</div>
			)}

			{fields.includes('nurseStaffRatios') && (
				<div className='w-full'>
					<div className='mb-2 font-preset-label'>Staff Ratios{nurseStaffRatios.isDirty ? ' (Changed)' : ''}</div>
					<div className='border rounded-sm border-gray-400 p-2'>
						<div className='flex justify-between items-baseline '>
							<p className='text-[0.7em] bg-blue-500 rounded-sm text-white px-1.5 py-0.5 pb-1'>Variable Staff</p>
							<div className='flex text-[0.8em] items-baseline mb-2'>
								<NurseStaffRatiosInput name={'variable'} filterState={nurseStaffRatios} />
							</div>
						</div>

						<div className='flex justify-between items-baseline'>
							<p className='text-[0.7em] bg-blue-500 rounded-sm text-white px-1.5 py-0.5 pb-1'>Indirect Staff</p>
							<div className='flex text-[0.8em] items-baseline mb-1'>
								<NurseStaffRatiosInput name={'indirect'} filterState={nurseStaffRatios} />
							</div>
						</div>
					</div>
				</div>
			)}

			{fields.includes('variableStaffWindow') && (
				<div className='w-full'>
					<div className='mb-2 font-preset-label'>
						Variable Staff Window{variableStaffWindow.isDirty ? ' (Changed)' : ''}
					</div>
					<div className='border rounded-sm border-gray-400 p-2'>
						<div className='w-full flex flex-row'>
							<p className='text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary mr-4 mt-3'>Start Time</p>
							<Timepicker
								interval={60}
								minHour={0}
								maxHour={parseInt(variableStaffWindow.applied.end.split(':')[0]) - 1}
								maxMinute={0}
								onChange={(time) => {
									if (!time) return;
									const currentVal = variableStaffWindow.selected;
									currentVal['start'] = time.military ?? '7:00:00';
									variableStaffWindow.update({
										selected: currentVal,
										applied: variableStaffWindow.applied,
									});
								}}
								selected={stringTimeToDate(variableStaffWindow.applied.start)}
								disabled={false}
							/>
						</div>

						<div className='mt-2 w-full flex flex-row'>
							<p className='text-p3 text-gray-700 tracking-wider uppercase mt-1 font-secondary mr-7 mt-3'>End Time</p>
							<Timepicker
								interval={60}
								minHour={parseInt(variableStaffWindow.applied.start.split(':')[0]) + 1}
								maxHour={24}
								maxMinute={0}
								onChange={(time) => {
									if (!time) return;
									const currentVal = variableStaffWindow.selected;
									currentVal['end'] = time.military ?? '17:00:00';
									variableStaffWindow.update({
										selected: currentVal,
										applied: variableStaffWindow.applied,
									});
								}}
								selected={stringTimeToDate(variableStaffWindow.applied.end)}
								disabled={false}
							/>
						</div>
					</div>
				</div>
			)}

			{fields.includes('indirectStaffWindow') && (
				<div className='w-full'>
					<div className='mb-2 font-preset-label'>
						Indirect Staff Window{indirectStaffWindow.isDirty ? ' (Changed)' : ''}
					</div>
					<div className='border rounded-sm border-gray-400 p-2'>
						<div className='w-full flex flex-row'>
							<p className='text-p3 text-gray-700 tracking-wider uppercase mb-1 font-secondary mr-4 mt-3'>Start Time</p>
							<Timepicker
								interval={60}
								minHour={0}
								maxHour={parseInt(indirectStaffWindow.applied.end.split(':')[0]) - 1}
								maxMinute={0}
								onChange={(time) => {
									if (!time) return;
									const currentVal = indirectStaffWindow.selected;
									currentVal['start'] = time.military ?? '7:00:00';
									indirectStaffWindow.update({
										selected: currentVal,
										applied: indirectStaffWindow.applied,
									});
								}}
								selected={stringTimeToDate(indirectStaffWindow.applied.start)}
								disabled={false}
							/>
						</div>

						<div className='mt-2 w-full flex flex-row'>
							<p className='text-p3 text-gray-700 tracking-wider uppercase mt-1 font-secondary mr-7 mt-3'>End Time</p>
							<Timepicker
								interval={60}
								minHour={parseInt(indirectStaffWindow.applied.start.split(':')[0]) + 1}
								maxHour={24}
								maxMinute={0}
								onChange={(time) => {
									if (!time) return;
									const currentVal = indirectStaffWindow.selected;
									currentVal['end'] = time.military ?? '17:00:00';
									indirectStaffWindow.update({
										selected: currentVal,
										applied: indirectStaffWindow.applied,
									});
								}}
								selected={stringTimeToDate(indirectStaffWindow.applied.end)}
								disabled={false}
							/>
						</div>
					</div>
				</div>
			)}

			{fields.includes('surgeon') && (
				<Select
					label={`Surgeon${surgeon.isDirty ? ' (Changed)' : ''}`}
					options={surgeon.all.map(({ id, name }) => ({ value: id, label: name }))}
					onChange={(selection) => {
						if (!selection) return;
						const selectedSurgeon = { id: selection.value, name: selection.label };
						surgeon.update({ selected: selectedSurgeon });
					}}
					value={surgeon.selected.id !== 0 ? { value: surgeon.selected.id, label: surgeon.selected.name } : null}
					isWorking={surgeon.isFetching}
				/>
			)}
			{fields.includes('blockName') && (
				<Select
					label={`Block Name${blockName.isDirty ? ' (Changed)' : ''}`}
					options={blockName.all.map(({ id, name }) => ({ value: id, label: name }))}
					onChange={(selection) => {
						if (!selection) return;
						const selectedBlockName = { id: selection.value, name: selection.label };
						blockName.update({ selected: selectedBlockName });
					}}
					value={blockName.selected.id !== 0 ? { value: blockName.selected.id, label: blockName.selected.name } : null}
					isWorking={blockName.isFetching}
				/>
			)}
			{fields.includes('blockNames') && (
				<MultiSelect
					label={`Block Names${blockNames.isDirty ? ' (Changed)' : ''}`}
					options={blockNames.all.map(({ id, name }) => ({ value: id, label: name }))}
					onChange={(selections) => {
						const selectedSurgeons = selections.map(({ value: id, label: name }) => ({ id, name }));
						blockNames.update({ selected: selectedSurgeons });
					}}
					value={blockNames.selected.map(({ id, name }) => ({ value: id, label: name }))}
					isWorking={blockNames.isFetching}
				/>
			)}
			{fields.includes('surgeons') && (
				<MultiSelect
					label={`Surgeons${surgeons.isDirty ? ' (Changed)' : ''}`}
					options={surgeons.all.map(({ id, name }) => ({ value: id, label: name }))}
					onChange={(selections) => {
						const selectedSurgeons = selections.map(({ value: id, label: name }) => ({ id, name }));
						surgeons.update({ selected: selectedSurgeons });
					}}
					value={surgeons.selected.map(({ id, name }) => ({ value: id, label: name }))}
					isWorking={surgeons.isFetching}
				/>
			)}
			{fields.includes('anesthesiologists') && (
				<MultiSelect
					label={`Anesthesiologists${anesthesiologists.isDirty ? '(Changed)' : ''}`}
					options={anesthesiologists.all.map(({ id, name }) => ({ value: id, label: name }))}
					onChange={(selections) => {
						const selectedAnesthesiologists = selections.map(({ value: id, label: name }) => ({ id, name }));
						anesthesiologists.update({ selected: selectedAnesthesiologists });
					}}
					value={anesthesiologists.selected.map(({ id, name }) => ({ value: id, label: name }))}
					isWorking={anesthesiologists.isFetching}
				/>
			)}
			{fields.includes('anesthetists') && (
				<MultiSelect
					label={`Anesthetists${anesthetists.isDirty ? '(Changed)' : ''}`}
					options={anesthetists.all.map(({ id, name }) => ({ value: id, label: name }))}
					onChange={(selections) => {
						const selectedAnesthetists = selections.map(({ value: id, label: name }) => ({ id, name }));
						anesthetists.update({ selected: selectedAnesthetists });
					}}
					value={anesthetists.selected.map(({ id, name }) => ({ value: id, label: name }))}
					isWorking={anesthetists.isFetching}
				/>
			)}
			{fields.includes('daysOfWeek') && (
				<MultiSelect
					label={`Days of Week${daysOfWeek.isDirty ? ' (Changed)' : ''}`}
					options={daysOfWeek.all.map((value, index) => ({ value: index + 1, label: value }))}
					onChange={(selections) => {
						const selectedOption = selections.map((selection) => selection.label);
						daysOfWeek.update({ selected: selectedOption });
					}}
					value={daysOfWeek.selected.map((value) => ({
						value: daysOfWeek.all.indexOf(value) + 1,
						label: value,
					}))}
				/>
			)}
			<div className='flex flex-col gap-4' onClick={handleClick}>
				{fields.includes('serviceLines') && (
					<MultiSelect
						label={`Service Lines${serviceLines.isDirty ? ' (Changed)' : ''}`}
						options={serviceLines.all.map(({ id, name }) => ({ value: id, label: name }))}
						onChange={(selections) => {
							const selectedServiceLines = selections.map(({ value: id, label: name }) => ({ id, name }));
							serviceLines.update({ selected: selectedServiceLines });
						}}
						value={serviceLines.selected.map(({ id, name }) => ({ value: id, label: name }))}
						isWorking={serviceLines.isFetching}
					/>
				)}

				{fields.includes('encounterTypes') && (
					<MultiSelect
						label={`Encounter Types${encounterTypes.isDirty ? ' (Changed)' : ''}`}
						options={encounterTypes.all.map(({ id, name }) => ({ value: id, label: name }))}
						onChange={(selections) => {
							const selectedEncounterTypes = selections.map(({ value: id, label: name }) => ({ id, name }));
							encounterTypes.update({ selected: selectedEncounterTypes });
						}}
						value={encounterTypes.selected.map(({ id, name }) => ({ value: id, label: name }))}
						isWorking={encounterTypes.isFetching}
					/>
				)}

				{fields.includes('rooms') && (
					<MultiSelect
						label={`Rooms ${rooms.isDirty ? ' (Changed)' : ''}`}
						options={rooms.all.map(({ id, name }) => ({ value: id, label: name }))}
						onChange={(selections) => {
							const selectedRooms = selections.map(({ value: id, label: name }) => ({ id, name }));
							rooms.update({ selected: selectedRooms });
						}}
						value={rooms.selected.map(({ id, name }) => ({ value: id, label: name }))}
						isWorking={rooms.isFetching}
					/>
				)}

				{fields.includes('turnoverTimeThreshold') && turnoverConcurrency.selected !== 'Excluded' && (
					<NumberField
						label={`Turnover Time Threshold${turnoverTimeThreshold.isDirty ? ' (Changed)' : ''}`}
						value={turnoverTimeThreshold.selected}
						min={turnoverTimeThreshold.min}
						max={turnoverTimeThreshold.max}
						step={turnoverTimeThreshold.step}
						onChange={(e) => {
							if (!e.currentTarget.value) return;
							turnoverTimeThreshold.update({ selected: Number(e.currentTarget.value) });
						}}
					/>
				)}

				{fields.includes('fcotsGraceMinutes') && (
					<NumberField
						label={`FCOTS Grace Minutes${fcotsGraceMinutes.isDirty ? ' (Changed)' : ''}`}
						value={fcotsGraceMinutes.selected}
						min={fcotsGraceMinutes.min}
						max={fcotsGraceMinutes.max}
						step={fcotsGraceMinutes.step}
						onChange={(e) => {
							if (!e.currentTarget.value) return;
							fcotsGraceMinutes.update({ selected: Number(e.currentTarget.value) });
						}}
					/>
				)}

				{fields.includes('heatmapMethod') && (
					<Select
						label={`Concurrency Method`}
						options={heatmapMethod.all.map(({ id, name }) => ({ value: id, label: name }))}
						onChange={(selection) => {
							if (!selection) return;
							const selectedHeatmapMethod = { id: selection.value, name: selection.label };
							heatmapMethod.update({ selected: selectedHeatmapMethod });
						}}
						value={{ value: heatmapMethod.selected.id, label: heatmapMethod.selected.name }}
						isWorking={heatmapMethod.isFetching}
					/>
				)}

				{fields.includes('phase') && (
					<Select
						label={`Phase`}
						options={phase.all.map(({ id, name }) => ({ value: id, label: name }))}
						onChange={(selection) => {
							if (!selection) return;
							const selectedPhase = { id: selection.value, name: selection.label };
							phase.update({ selected: selectedPhase });
						}}
						value={{ value: phase.selected.id, label: phase.selected.name }}
						isWorking={phase.isFetching}
					/>
				)}

				{fields.includes('percentile') && heatmapMethod.selected.id === 1 && (
					<NumberField
						label={`Percentile${percentile.isDirty ? ' (Changed)' : ''}`}
						value={percentile.selected}
						min={percentile.min}
						max={percentile.max}
						step={percentile.step}
						onChange={(e) => {
							if (!e.currentTarget.value) return;
							percentile.update({ selected: Number(e.currentTarget.value) });
						}}
					/>
				)}

				{fields.includes('primetime') && (
					<div>
						<div className='mb-2 font-preset-label'>Primetime{primetime.isDirty ? ' (Changed)' : ''}</div>
						<div className='flex flex-col'>
							{primetime.all.map((value) => (
								<RadioButton
									key={value}
									label={value}
									name='primetime'
									className='text-p3 text-gray-700'
									checked={primetime.selected === value}
									onChange={() => primetime.update({ selected: value })}
								/>
							))}
						</div>
					</div>
				)}

				{fields.includes('mdaCoverRoom') && (
					<div>
						<div className='mb-2 font-preset-label'>MDA Covers Own Room{mdaCoverRoom.isDirty ? ' (Changed)' : ''}</div>
						<div className='flex flex-col'>
							<RadioButton
								key={'yes'}
								label={'Yes'}
								name='mda_room_cover'
								className='text-p3 text-gray-700'
								checked={mdaCoverRoom.selected}
								onChange={() => mdaCoverRoom.update({ selected: true })}
							/>
							<RadioButton
								key={'no'}
								label={'No'}
								name='mda_room_cover'
								className='text-p3 text-gray-700'
								checked={!mdaCoverRoom.selected}
								onChange={() => mdaCoverRoom.update({ selected: false })}
							/>
						</div>
					</div>
				)}

				{fields.includes('addOns') && (
					<div>
						<div className='mb-2 font-preset-label'>Add-Ons{addOns.isDirty ? ' (Changed)' : ''}</div>
						<div className='flex flex-col'>
							{addOns.all.map((value) => (
								<RadioButton
									key={value}
									label={value}
									name='addOns'
									className='text-p3 text-gray-700'
									checked={addOns.selected === value}
									onChange={() => addOns.update({ selected: value })}
								/>
							))}
						</div>
					</div>
				)}

				{fields.includes('turnoverConcurrency') && (
					<div>
						<div className='mb-2 font-preset-label'>
							Turnover Concurrency{turnoverConcurrency.isDirty ? ' (Changed)' : ''}
						</div>
						<div className='flex flex-col'>
							<RadioButton
								key={'included'}
								label={'Included'}
								name='turnover_concurrency'
								className='text-p3 text-gray-700'
								checked={turnoverConcurrency.selected === 'Included'}
								onChange={() => turnoverConcurrency.update({ selected: 'Included' })}
							/>
							<RadioButton
								key={'excluded'}
								label={'Excluded'}
								name='turnover_concurrency'
								className='text-p3 text-gray-700'
								checked={turnoverConcurrency.selected === 'Excluded'}
								onChange={() => turnoverConcurrency.update({ selected: 'Excluded' })}
							/>
							<RadioButton
								key={'only_turnover'}
								label={'Only Turnover'}
								name='turnover_concurrency'
								className='text-p3 text-gray-700'
								checked={turnoverConcurrency.selected === 'Only Turnover'}
								onChange={() => turnoverConcurrency.update({ selected: 'Only Turnover' })}
							/>
						</div>
					</div>
				)}

				{fields.includes('procedures') && (
					<div>
						<div className='mb-2 font-preset-label'>Robotic{procedures.isDirty ? ' (Changed)' : ''}</div>
						<div className='flex flex-col'>
							{proceduresSelected.map((value) => (
								<RadioButton
									key={value.value}
									label={value.key}
									name='robotic'
									className='text-p3 text-gray-700'
									checked={value.checked}
									onChange={(selections) => {
										const selectedProcedures = [{ id: 1, name: value.value }];
										procedures.update({ selected: selectedProcedures });
									}}
								/>
							))}
						</div>
					</div>
				)}

				{fields.includes('timeslotType') && (
					<div>
						<div className='mb-2 font-preset-label'>Timeslot Type{timeslotType.isDirty ? ' (Changed)' : ''}</div>
						<div className='flex flex-col'>
							{timeslotType.all.map((value) => (
								<RadioButton
									key={value}
									label={value}
									name='timeslotType'
									className='text-p3 text-gray-700'
									checked={timeslotType.selected === value}
									onChange={() => timeslotType.update({ selected: value })}
								/>
							))}
						</div>
					</div>
				)}

				{fields.includes('blockTimeslot') && (
					<MultiSelect
						label={`Block Timeslot${blockTimeslot.isDirty ? ' (Changed)' : ''}`}
						options={blockTimeslot.all.map((value, index) => ({ value: index + 1, label: value }))}
						onChange={(selections) => {
							const selectedOption = selections.map((selection) => selection.label);
							blockTimeslot.update({ selected: selectedOption });
						}}
						value={blockTimeslot.selected.map((value) => ({
							value: blockTimeslot.all.indexOf(value) + 1,
							label: value,
						}))}
					/>
				)}

				{fields.includes('utilizationType') && (
					<div>
						<div className='mb-2 font-preset-label'>Utilization Type{utilizationType.isDirty ? ' (Changed)' : ''}</div>
						<div className='flex flex-col'>
							{utilizationType.all.map((value) => (
								<RadioButton
									key={value}
									label={value}
									name='utilizationType'
									className='text-p3 text-gray-700'
									checked={utilizationType.selected === value}
									onChange={() => utilizationType.update({ selected: value })}
								/>
							))}
						</div>
					</div>
				)}
				{fields.includes('abandonedDaysToggle') && (
					<div>
						<div className='mb-2 font-preset-label'>
							Abandoned Days Toggle{abandonedDaysToggle.isDirty ? ' (Changed)' : ''}
						</div>
						<div className='flex flex-col'>
							{abandonedDaysToggle.all.map((value) => (
								<RadioButton
									key={value}
									label={value}
									name='abandonedDaysToggle'
									className='text-p3 text-gray-700'
									checked={abandonedDaysToggle.selected === value}
									onChange={() => abandonedDaysToggle.update({ selected: value })}
								/>
							))}
						</div>
					</div>
				)}
			</div>
			<div ref={ref} />
		</div>
	);
}

interface SalaryInputProps {
	name: string;
	filterState: FilterContextField<SalaryState>;
}

function SalaryInput({ name, filterState }: SalaryInputProps) {
	return (
		<TextField
			hideLabel={true}
			isWorking={filterState.isFetching}
			onChange={(e) => {
				const newVal = e.currentTarget.value.replaceAll(',', '');
				const currentVal = filterState.selected;
				let currentNumber = parseInt(newVal.length > 0 ? newVal : '0');
				if (isNaN(currentNumber)) {
					currentNumber = 0;
				}
				currentVal[name as keyof Salary] = currentNumber > 9999999 ? 9999999 : currentNumber;
				filterState.update({
					selected: currentVal,
					applied: filterState.applied,
				});
			}}
			className='h-[1.8em] w-[7em] text-p2 border border-blue-400 border-t-0 border-r-0 border-l-0'
			label={''}
			value={filterState.selected[name as keyof Salary].toLocaleString('en-US') ?? '0'}
		/>
	);
}

interface NurseStaffRatiosInputProps {
	name: string;
	filterState: FilterContextField<NurseStaffRatioState>;
}

function NurseStaffRatiosInput({ name, filterState }: NurseStaffRatiosInputProps) {
	return (
		<NumberField
			label={''}
			value={filterState.selected[name as keyof NurseStaffRatio]}
			min={0}
			max={5}
			showRangeInput={false}
			step={0.01}
			errorMessageArea={'none'}
			errorMessage={''}
			onChange={(e) => {
				if (!e.currentTarget.value) return;
				const currentVal = filterState.selected;
				const currentNumber = parseFloat(e.currentTarget.value);
				currentVal[name as keyof NurseStaffRatio] = currentNumber > 9999999 ? 9999999 : currentNumber;
				filterState.update({
					selected: currentVal,
					applied: filterState.applied,
				});
			}}
		/>
	);
}

export { FilterFields, FilterFields as default };
