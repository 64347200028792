import { ApiEndpoints } from 'models/api';
import { StaffingGridItem } from 'pages/Anesthesia/components';
import { standardApi } from 'store/api';

const filterApi = standardApi.enhanceEndpoints({ addTagTypes: ['Notifications'] });

export interface NotificationInboxItem {
	id: number;
	category: string;
	subject: string;
	message: string;
	date: string;
	recipient: number;
	read_status?: boolean;
	merlin_page_url?: string;
}

interface NotificationInboxResponse {
	notifications: NotificationInboxItem[];
}

interface NotificationOptionItem {
	title: string;
	description: string;
	merlin_page_url: string;
	type: string;
	enable_multi_user_selection_for_admin: boolean;
}

/**
 * Provides endpoints for data values in database
 */
export const filterService = filterApi.injectEndpoints({
	endpoints: (build) => ({
		getNotificationInbox: build.query<NotificationInboxResponse, undefined>({
			query: (body) => ({
				url: ApiEndpoints.GET_NOTIFICATION_INBOX,
				method: 'POST',
				body,
			}),
			providesTags: ['Notifications'],
		}),
		updateNotification: build.mutation<undefined, { notification_id: number; read_status: boolean }>({
			query: (body) => ({
				url: ApiEndpoints.UPDATE_NOTIFICATION,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Notifications'],
		}),
		setNotificationSchedule: build.mutation<
			undefined,
			{
				notification_name: string;
				healthsystem_id: number | null;
				delete_schedule?: boolean;
				merlin_page_url: string;
				user_ids: number[];
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.SET_NOTIFICATION_SCHEDULE,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Notifications'],
		}),
		getNotificationSubscription: build.query<
			{
				subscriptions: string[];
			},
			{
				healthsystem_id: number | null;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_NOTIFICATION_SUBSCRIPTIONS,
				method: 'POST',
				body,
			}),
			providesTags: ['Notifications'],
		}),
		getNotificationSubscriptionOptions: build.query<
			{
				subscriptionOptions: NotificationOptionItem[];
			},
			{
				healthsystem_id: number | null;
			}
		>({
			query: (body) => ({
				url: ApiEndpoints.GET_NOTIFICATION_SUBSCRIPTION_OPTIONS,
				method: 'POST',
				body,
			}),
			providesTags: ['Notifications'],
		}),
	}),
});

export const {
	useGetNotificationInboxQuery,
	useUpdateNotificationMutation,
	useSetNotificationScheduleMutation,
	useGetNotificationSubscriptionQuery,
	useGetNotificationSubscriptionOptionsQuery,
} = filterService;

export const {
	endpoints: {
		getNotificationInbox,
		updateNotification,
		setNotificationSchedule,
		getNotificationSubscription,
		getNotificationSubscriptionOptions,
	},
} = filterService;
