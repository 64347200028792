import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, TextField } from 'components';
import { defaultRequestResetPasswordFormInputValues, requestResetPasswordFormSchema } from 'models/authentication';
import { AuthPage } from 'pages/Auth';
import { getOktaErrorMessage } from 'utils/authentication';
import { Routes } from 'models/navigation';
import { useAuth, useToast } from 'context';
import {
	getQueryError,
	handleApiResponse,
	useRequestPasswordResetAdminMutation,
	useRequestPasswordResetMutation,
	useSystem,
} from 'store';
import { UserRole } from 'models';

import type { AnyAuthContext } from 'context';
import type { RequestResetPasswordFormInputs } from 'models/authentication';
import type { SubmitHandler } from 'react-hook-form';

export function RequestResetPassword() {
	const auth = useAuth<AnyAuthContext>();
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isDirty, isSubmitting, isSubmitted },
	} = useForm<RequestResetPasswordFormInputs>({ resolver: yupResolver(requestResetPasswordFormSchema) });
	const [errorMessage, setErrorMessage] = useState('');
	const [showSuccess, setShowSuccess] = useState(false);
	const [sendPasswordResetEmail] = useRequestPasswordResetMutation();
	const [sendPasswordResetEmailAdmin] = useRequestPasswordResetAdminMutation();
	const { data: currentUser } = useSystem();
	const isAdmin = currentUser?.user.role === UserRole.admin;
	const toast = useToast();

	const onSubmit: SubmitHandler<RequestResetPasswordFormInputs> = async (formData) => {
		const payload = {
			email: formData.email,
		};

		const response = isAdmin ? await sendPasswordResetEmailAdmin(payload) : await sendPasswordResetEmail(payload);

		handleApiResponse(response, {
			success: (payload) => {
				toast.createToast({
					title: `Password reset request received`,
					variant: 'success',
				});
				setShowSuccess(true);
			},
			error: (payload) => {
				toast.createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
				setShowSuccess(false);
			},
		});
	};

	// When an authentication error occurs, keep the form's values but reset its
	// dirty state. Then when the form becomes dirty again, clear the error since
	// the user has indicated they are trying again.
	useEffect(() => {
		const clearAuthError = auth.clearError;

		if (auth.status === 'api_error') {
			if (isSubmitted) {
				reset(defaultRequestResetPasswordFormInputValues, { keepValues: true });
			} else if (isDirty) {
				clearAuthError();
			}
		}
	}, [auth.status, auth.clearError, isSubmitted, isDirty, reset]);

	// Provide a more detailed authentication error message based on the error
	// code returned by the Okta API.
	useEffect(() => {
		if (auth.status === 'api_error') {
			setErrorMessage(getOktaErrorMessage(auth.data.error.errorCode));
		} else {
			setErrorMessage('');
		}
	}, [auth.status, auth.data]);

	return (
		<AuthPage>
			<div className='flex'>
				<div className='flex max-w-xl w-full'>
					<div className='m-auto pt-7 px-8 max-w-md w-full'>
						{isAdmin ? (
							<>
								<h1 className='text-p2 text-blue-500 font-semibold'>[ADMIN MODE]</h1>
								<h1 className='font-semibold text-h4'>Send User Password Reset Email</h1>
							</>
						) : (
							<h1 className='font-semibold text-h4'>Reset Your Password</h1>
						)}

						<form className='flex flex-col mt-8' onSubmit={handleSubmit(onSubmit)} noValidate autoComplete='off'>
							{showSuccess && (
								<div className='text-p3 flex items-center justify-around mb-4 bg-blue-500 rounded-md text-white py-3'>
									<span className='material-symbol mx-3'>check_circle</span>
									<p>If an account exists with this email, you’ll get instructions to reset your password.</p>
								</div>
							)}

							{!showSuccess && (
								<>
									<TextField
										label='Email Address'
										type='email'
										sizeY='lg'
										defaultValue={defaultRequestResetPasswordFormInputValues.email}
										errorMessage={errors.email?.message}
										{...register('email')}
									/>
									<Link to={Routes.HOME} className='self-start font-secondary text-p2 mt-3'>
										<span className='material-symbol-sm relative top-[5px] mr-1'>keyboard_backspace</span>
										Back to Login
									</Link>
									<Button className='mt-12' sizeY='lg' isWorking={isSubmitting} disabled={!isDirty}>
										{isSubmitting ? 'Submitting' : 'Submit'}
									</Button>
								</>
							)}

							<p className='mt-2 text-p2 text-red-400'>{errorMessage || <>&nbsp;</>}</p>
						</form>
					</div>
				</div>
			</div>

			<div className='bg-merlinBlue'></div>
		</AuthPage>
	);
}

export default RequestResetPassword;
