import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button, Checkbox, TextField } from 'components';
import { defaultRegisterFormInputValues, passwordChecklistItems, registerFormSchema } from 'models/authentication';
import { AuthPage } from 'pages/Auth';
import { clearSessionCache, getOktaErrorMessage } from 'utils/authentication';
import { Routes } from 'models/navigation';
import { useAuth, useToast } from 'context';
import { getQueryError, handleApiResponse, useRegisterUserMutation } from 'store';

import type { AnyAuthContext } from 'context';
import type { RegisterFormInputs } from 'models/authentication';
import type { SubmitHandler } from 'react-hook-form';

export function Register() {
	const auth = useAuth<AnyAuthContext>();
	const {
		register,
		handleSubmit,
		reset,
		getValues,
		setValue,
		formState: { errors, isDirty, isSubmitting, isSubmitted, isValid },
	} = useForm<RegisterFormInputs>({ resolver: yupResolver(registerFormSchema) });
	const [formValues, setFormValues] = useState({ email: '', password: '' });
	const [errorMessage, setErrorMessage] = useState('');
	const [registerUser] = useRegisterUserMutation();
	const toast = useToast();

	// read `token` param from url for auth at endpoint
	const token = new URLSearchParams(window.location.search).get('token');
	const query_first_name = new URLSearchParams(window.location.search).get('first') ?? '';
	const query_last_name = new URLSearchParams(window.location.search).get('last') ?? '';
	const query_email = new URLSearchParams(window.location.search).get('email') ?? '';

	// set form defaults to user provided query params
	useEffect(() => {
		setValue('firstName', query_first_name);
		setValue('lastName', query_last_name);
		setValue('email', query_email);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query_first_name, query_last_name, query_email]);

	const onSubmit: SubmitHandler<RegisterFormInputs> = async (formData) => {
		const response = await registerUser({
			first_name: formData.firstName,
			last_name: formData.lastName,
			email: formData.email,
			password: formData.password,
			token: token ?? 'none',
		});

		handleApiResponse(response, {
			success: () => {
				toast.createToast({
					title: `Successfully created account, logging in...`,
					variant: 'success',
				});

				auth.login(formData.email, formData.password, '/');
			},
			error: (payload) => {
				toast.createToast({
					title: getQueryError(payload),
					variant: 'error',
				});
			},
		});
	};

	// When an authentication error occurs, keep the form's values but reset its
	// dirty state. Then when the form becomes dirty again, clear the error since
	// the user has indicated they are trying again.
	useEffect(() => {
		const clearAuthError = auth.clearError;

		if (auth.status === 'api_error') {
			if (isSubmitted) {
				reset(defaultRegisterFormInputValues, { keepValues: true });
			} else if (isDirty) {
				clearAuthError();
			}
		}
	}, [auth.status, auth.clearError, isSubmitted, isDirty, reset]);

	// Provide a more detailed authentication error message based on the error
	// code returned by the Okta API.
	useEffect(() => {
		if (auth.status === 'api_error') {
			setErrorMessage(getOktaErrorMessage(auth.data.error.errorCode));
		} else {
			setErrorMessage('');
		}
	}, [auth.status, auth.data]);

	// clear cache once on comp load
	useEffect(() => {
		clearSessionCache();
	}, []);

	return (
		<AuthPage>
			<div className='flex'>
				<div className='flex max-w-xl w-full'>
					<div className='m-auto pt-7 px-8 max-w-md w-full'>
						<h1 className='font-semibold text-h4'>Sign Up to use Merlin!</h1>

						<form className='flex flex-col mt-8' onSubmit={handleSubmit(onSubmit)} noValidate autoComplete='off'>
							<TextField
								containerProps={{ className: 'my-2' }}
								label='First Name'
								type='text'
								sizeY='md'
								defaultValue={defaultRegisterFormInputValues.firstName}
								errorMessage={errors.firstName?.message}
								{...register('firstName')}
							/>
							<TextField
								containerProps={{ className: 'my-2' }}
								label='Last Name'
								type='lastName'
								sizeY='md'
								defaultValue={defaultRegisterFormInputValues.lastName}
								errorMessage={errors.lastName?.message}
								{...register('lastName')}
							/>
							<TextField
								containerProps={{ className: 'my-2' }}
								label='Email Address'
								type='email'
								sizeY='md'
								defaultValue={defaultRegisterFormInputValues.email}
								errorMessage={errors.email?.message}
								disabled
								onInput={() => {
									setFormValues({
										...formValues,
										email: getValues('email'),
									});
								}}
								{...register('email')}
							/>
							<TextField
								containerProps={{ className: 'my-2' }}
								label='Create Password'
								type='password'
								sizeY='md'
								defaultValue={defaultRegisterFormInputValues.password}
								autoComplete={'none'}
								errorMessage={errors.password?.message}
								onInput={() => {
									setFormValues({
										...formValues,
										password: getValues('password'),
									});
								}}
								{...register('password')}
							/>
							<div className='my-2'>
								{passwordChecklistItems.map((item, index) => (
									<div key={index} className='flex my-2 text-p2'>
										<div className='flex-initial w-8'>
											<span
												className={classNames(
													'material-symbol relative -top-[2px]',
													item.test(getValues('password'), getValues('email')) ? 'text-green-500' : 'text-gray-400'
												)}
											>
												check_circle
											</span>
										</div>
										<div className='grow'>{item.text}</div>
									</div>
								))}
							</div>
							<TextField
								containerProps={{ className: 'my-2' }}
								label='Confirm Password'
								type='password'
								sizeY='md'
								defaultValue={defaultRegisterFormInputValues.confirmPassword}
								errorMessage={errors.confirmPassword?.message}
								{...register('confirmPassword')}
							/>

							<div className='my-4'>
								<Checkbox
									className='font-secondary text-p2 gap-3'
									label={
										<p>
											By signing up, you agree to the <Link to={Routes.TERMS}>Terms &amp; Conditions</Link>
										</p>
									}
									value='terms'
									defaultValue={defaultRegisterFormInputValues.terms}
									errorMessage={errors.terms?.message}
									{...register('terms')}
								/>
							</div>

							<Button className='mt-12' sizeY='lg' isWorking={isSubmitting} disabled={!isDirty && !isValid}>
								{isSubmitting ? 'Submitting' : 'Submit'}
							</Button>
							<p className='mt-2 text-p2 text-red-400'>{errorMessage || <>&nbsp;</>}</p>

							<div>
								<Link to={Routes.HOME} className='self-start font-secondary text-p2'>
									<span className='material-symbol-sm relative top-[5px] mr-1'>keyboard_backspace</span>
									Back to Login
								</Link>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div className='bg-merlinBlue'></div>
		</AuthPage>
	);
}

export default Register;
