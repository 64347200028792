import { ApiEndpoints } from 'models/api';
import { standardApi } from 'store/api';

export interface UploadedFile {
	date_of_upload: string;
	file_size: string;
	filename: string;
	file_type: string;
	upload_id: number;
	status: null | boolean;
	uploaded_by: string;
	processing_message: string;
	metadata: {
		cases_success_count: number;
		facility_name: string;
		upload_id: number;
		message: string;
		status: boolean | null;
		issues: { name: string; value: number }[];
		total_cases_count: number;
		unrecognized_surgeons_count: number;
		unrecognized_rooms_count: number;
		unrecognized_service_lines_count: number;
		unrecognized_encounter_types_count: number;
		scheduled_start_missing_count: number;
		duration_error_count: number;
	}[];
}

export interface GetFilesResponse {
	files: UploadedFile[];
}

export interface UploadResponse {
	file_uploaded: string;
}

const apiWithTags = standardApi.enhanceEndpoints({ addTagTypes: ['Uploads'] });

/**
 * Provides endpoints for setting values in database
 */
export const fileService = apiWithTags.injectEndpoints({
	endpoints: (build) => ({
		uploadFile: build.mutation<UploadResponse, FormData>({
			query: (body: FormData) => ({
				url: ApiEndpoints.FILE_UPLOAD,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Uploads'],
		}),
		reProcessUpload: build.mutation<{ upload_id: number; message: string }, { upload_id: number; delete: boolean }>({
			query: (body) => ({
				url: ApiEndpoints.REPROCESS_UPLOAD,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Uploads'],
		}),
		getFile: build.query<GetFilesResponse, { facility_id?: number | null; healthsystem_id?: number | null }>({
			query: ({ facility_id, healthsystem_id }) => ({
				url: ApiEndpoints.GET_UPLOADS,
				method: 'GET',
				params: { facility_id, healthsystem_id },
			}),
			providesTags: ['Uploads'],
		}),
	}),
});

export const { useUploadFileMutation, useGetFileQuery, useReProcessUploadMutation } = fileService;

export const {
	endpoints: { uploadFile, getFile, reProcessUpload },
} = fileService;
