import { AnyAuthContext, useAuth } from 'context';
import { useAppSelector } from 'store';
import { useGetSystemsQuery } from 'store/services';

/**
 * A hook that makes it simple to get the current cached/selected healthsystem, facilities, and user (with merlin-db settings)
 * @returns InitStateApiResponse in data
 * Usage:
 * const { data } = useSystem();
 * The logical reason for never getting isLoading, isError, etc. is because this query should be cached at this point. The data retrieval will be immediate.
 */
export function useSystem() {
	const { selectedSystem } = useAppSelector((state) => state.userState);
	const auth = useAuth<AnyAuthContext>();
	return useGetSystemsQuery(
		{
			healthsystem_id: selectedSystem,
		},
		{
			skip: auth.status !== 'authenticated',
		}
	);
}

export default useSystem;
